import { Component, OnInit } from '@angular/core';
import {Client} from "../client";
import{FormBuilder,FormGroup,Validators} from '@angular/forms';
import{ApiService} from '../api.service';
import { Router ,Params,ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-editclient',
  templateUrl: './editclient.component.html',
  styleUrls: ['./editclient.component.css']
})
export class EditclientComponent implements OnInit {

  constructor(private formBuilder:FormBuilder,
              private _apiService:ApiService,
              private router:Router,
              private routes:ActivatedRoute) { }

  addForm:FormGroup;
  client:Client
  ngOnInit(): void {
    const routeParams=this.routes.snapshot.params;

    this.addForm=this.formBuilder.group({
    telephone:['',[Validators.required,Validators.maxLength(8)]],
    email:['',[Validators.required]],
    nom:['',[Validators.required]],
    prenom:['',[Validators.required]],
    site:['',[Validators.required]],
    lien:['',[Validators.required]],
    taux:['',[Validators.required]],
    tauxMoov:['',[Validators.required]],
    tauxOrange:['',[Validators.required]],
    tauxJofe:['',[Validators.required]],
    tauxVisa:['',[Validators.required]]
    //montant:['',[Validators.required]]
    });
    this._apiService.getClientById(routeParams.id).
        subscribe((data:any)=>{
//console.log(data)
          this.addForm.patchValue(data);
        //  console.log(  this.addForm.value)
        });

  }
  update(){
    const routeParams=this.routes.snapshot.params;
this._apiService.updateClient(this.addForm.value.nom,this.addForm.value.prenom,this.addForm.value.email,this.addForm.value.telephone, this.addForm.value.site,this.addForm.value.lien,this.addForm.value.taux,this.addForm.value.tauxMoov,this.addForm.value.tauxOrange,this.addForm.value.tauxJofe, this.addForm.value.tauxVisa,routeParams.id).subscribe(()=>{
this.router.navigate(['/viewclient']);
},
error => {
  alert(error);
});
}
back(){
  this.router.navigateByUrl('/viewapi')
}

}
