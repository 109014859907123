import { QueryList,Input ,ViewChildren,Component, OnInit, ElementRef, HostListener, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
//import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import{ApiService} from '../api.service';
import{Api} from '../api';
import{Trace} from '../traces';
import { Router } from "@angular/router";
import {DecimalPipe} from '@angular/common';

import {Observable} from 'rxjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap' ;

import {SortableDirective, SortEvent} from '../sortable.directive';



import{TraceserviceService} from '../traceservice.service';
import {
    EditSettingsModel,
    CommandModel,
    GridComponent
} from '@syncfusion/ej2-angular-grids';
@Component({
  selector: 'app-onea',
  templateUrl: './onea.component.html',
  styleUrls: ['./onea.component.css']
})
export class OneaComponent implements OnInit {

  @ViewChild('grid', {static: false})
      public grid: GridComponent;

  public formatoptions =  {type:'date', format:'dd/MM/yyyy'}
  readonly editSetting: EditSettingsModel = {
        allowAdding: false,
        allowDeleting: true,
        allowEditing: true,
        allowEditOnDblClick: false,
        showConfirmDialog: true,
        showDeleteConfirmDialog: true
    };

      public filterSettings: any;

      onea:any
      oneas:any="../assets/images/onea.jpg"

  constructor(private _apiService:ApiService,
            private router:Router) { }

  ngOnInit(): void {
    this.filterSettings = {type: 'Menu'};
//var x="44gffgngjkfg43222".charCodeAt()
//console.log(x)
  this.loadOnea();
  }

    loadOnea(){
      this._apiService.getOnea().subscribe((data:any)=>{
        this.onea=data
        for (let index = 0; index < this.onea.length; index++) {
          var date=new Date(this.onea[index]["created_at"])
          var x=date.toUTCString()
          let array=x.split(" ")
          var date1=array[1]+"/"+array[2]+"/"+array[3]
          var h=array[4]
          this.onea[index]["date"]=date1
          this.onea[index]["heure"]=h

        }
      })
    }

}
