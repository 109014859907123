import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgForm } from '@angular/forms';
import{FormBuilder,FormGroup,Validators} from '@angular/forms';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { RouterModule, Routes } from '@angular/router';
import { NgModel } from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import{FormsModule} from '@angular/forms';
import{ReactiveFormsModule} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiService } from './api.service';
import { ApiComponent } from './api/api.component';
import { ViewapiComponent } from './viewapi/viewapi.component';
import { ApimenuComponent } from './apimenu/apimenu.component';


import { TraceComponent } from './trace/trace.component';

import { LoginComponent } from './login/login.component';
import { SortableDirective } from './sortable.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap' ;
import {DecimalPipe} from '@angular/common';
import { PaiementComponent } from './paiement/paiement.component';
import { ClientComponent } from './client/client.component';
import { ViewclientComponent } from './viewclient/viewclient.component';
import { EditclientComponent } from './editclient/editclient.component';
import { PayementsortantComponent } from './payementsortant/payementsortant.component';

import { MaskedTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import {FilterService, GridModule, GroupService, PageService, SortService, CommandColumnService} from '@syncfusion/ej2-angular-grids';
import {ButtonAllModule, CheckBoxModule, RadioButtonAllModule} from '@syncfusion/ej2-angular-buttons';
import { DropDownTreeModule } from '@syncfusion/ej2-angular-dropdowns';
import {DropDownListAllModule, MultiSelectAllModule} from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import {NumericTextBoxAllModule, TextBoxAllModule, ColorPickerModule} from '@syncfusion/ej2-angular-inputs';
import {MenuAllModule, SidebarModule} from '@syncfusion/ej2-angular-navigations';
import { DialogAllModule } from '@syncfusion/ej2-angular-popups';
import { TabAllModule } from '@syncfusion/ej2-angular-navigations';

//import { HttpInterceptorService } from './httpInterceptor.service';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { PaypalComponent } from './paypal/paypal.component';
import { HistoclientComponent } from './histoclient/histoclient.component';
import { RebourComponent } from './rebour/rebour.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CadreComponent } from './cadre/cadre.component';
import {NgxPayPalModule  } from "ngx-paypal";
import { TokengeneratorComponent } from './tokengenerator/tokengenerator.component';
import { VisaComponent } from './visa/visa.component';
import { EditclientpassComponent } from './editclientpass/editclientpass.component';
import { CardComponent } from './card/card.component';
import { ProfilComponent } from './profil/profil.component';
import { RessetPassComponent } from './resset-pass/resset-pass.component';
import { ValidationComponent } from './validation/validation.component';
import { SonabelComponent } from './sonabel/sonabel.component';
import { SonabelLoginComponent } from './sonabel-login/sonabel-login.component';
import { OneaComponent } from './onea/onea.component';
import { OneaLoginComponent } from './onea-login/onea-login.component';

declare global {
  interface Window {
    NI?: any;
  }
}





@NgModule({
  declarations: [
    AppComponent,
    ApiComponent,
    ViewapiComponent,
    ApimenuComponent,
    TraceComponent,
    LoginComponent,
    SortableDirective,
    PaiementComponent,
    ClientComponent,
    ViewclientComponent,
    EditclientComponent,
    PayementsortantComponent,
    PaypalComponent,
    HistoclientComponent,
    RebourComponent,
    CadreComponent,
    TokengeneratorComponent,
    VisaComponent,
    EditclientpassComponent,
    CardComponent,
    ProfilComponent,
    RessetPassComponent,
    ValidationComponent,
    SonabelComponent,
    SonabelLoginComponent,
    OneaComponent,
    OneaLoginComponent









  ],
  imports: [

  BrowserModule,
  AppRoutingModule,
  FormsModule,
  HttpClientModule,
  ReactiveFormsModule,
  NgbModule,
  NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 10000,

    }),
    ClarityModule,
    BrowserAnimationsModule,
    NgxPayPalModule,
    DialogAllModule,
    MaskedTextBoxModule,
    SidebarModule,
    GridAllModule,
    MultiSelectAllModule,
    DatePickerAllModule,
    ToolbarModule,
    CheckBoxModule,
    ColorPickerModule,
    DropDownTreeModule,
    NumericTextBoxAllModule,
    DropDownListAllModule,
    MenuAllModule,
    TextBoxAllModule,
    RadioButtonAllModule,
    ButtonAllModule,
    TabAllModule


  ],

  providers: [ApiService,
             LoginComponent,
              ApimenuComponent,
              DecimalPipe,
              DatePipe,PageService, SortService, FilterService, GroupService, CommandColumnService,FormBuilder
          /*  { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }*/],
  bootstrap: [AppComponent]
})


export class AppModule { }
