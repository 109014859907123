import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import{FormBuilder,FormGroup,Validators} from '@angular/forms';
import {ApiService  } from "../api.service";
import {  Subscription} from "rxjs";
@Component({
  selector: 'app-sonabel-login',
  templateUrl: './sonabel-login.component.html',
  styleUrls: ['./sonabel-login.component.css']
})
export class SonabelLoginComponent implements OnInit {

  addForm:FormGroup;
  show: boolean;
  _id:string;
  sonabel:any="../assets/images/sonabel.png"
  errorM:string;
  x:Subscription
  aut:string
  loader=false
  isAth:boolean=false
  bol:boolean=false
  constructor(private formBuilder:FormBuilder,private _apiService:ApiService,private router:Router) { this.show = false;}



  ngOnInit(): void {
    //this.initAdmin()
    //this.testCode()
    this.addForm=this.formBuilder.group({

  username:['',[Validators.required]],
  mdp:['',[Validators.required]],
  });
  }

  password() {
      this.show = !this.show;
  }

  initAdmin(){
  this._apiService.initAdmin().subscribe((res:any)=>{
    console.log(res)
  })
  }

  testCode(){
  var x="Sat, 29 Dec 2018 18:30:00 GMT"
  let array=x.split(" ")
  var date=array[1]+"/"+array[2]+"/"+array[3]
  var h=array[4]

  console.log(date)
  console.log(h)


  }

  testwindow(){

  var win=window.open('/apimenu/pzz/joffres/xy','menubar=0','toolbar=no') ;
  win.addEventListener('DOMContentLoaded', function () {
    win.history.replaceState(null, null, 'www.jofedigital.com');   // must be same domain (or ignore domain)
  })
  }
  conect(){
    this.loader=true
   //this.x=  this._apiService.authenticates(this.addForm.value.username,this.addForm.value.mdp)

   this._apiService.authenticateSonabel(this.addForm.value.username,this.addForm.value.mdp).subscribe((res:any)=>{
    //  this.aut=res.toString();
    this.loader=false
      if(res.toString()==="true"){
        //console.log(res.toString())
        this._apiService.isAthSonabel=true
        this.router.navigate(['/sonabel']);
      }

      else{
        this.loader=false
        this.errorM="Identifiants incorrects,reessayez!"
        this.bol=true
        this._apiService.isAthSonabel=false
        setTimeout(()=>{
     this.errorM=null;
   },4000)
        this.router.navigateByUrl('/sonabelLogin');
      }
      //console.log(res.toString())
    }
  );

  }


}
