import { Component, OnInit } from '@angular/core';
import{FormBuilder,FormGroup,Validators} from '@angular/forms'
import { Router } from "@angular/router";
import{ApiService} from '../api.service';
import{Client} from '../client';
@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

addForm:FormGroup;
taille=20
errorM=""
  constructor(private formBuilder:FormBuilder,
              private _apiService:ApiService,
              private router:Router) { }

  ngOnInit(): void {
    this.initFormOM()
  }
  initFormOM(){


    //console.log(this.Message_type)
    this.addForm=this.formBuilder.group({
    numero:['',[Validators.required,Validators.maxLength(8)]],
    mail:['',[Validators.required,Validators.email]],
    nom:['',[Validators.required]],
    password:['',[Validators.required,Validators.minLength(6),Validators.maxLength(30)]],
    password2:['',[Validators.required,Validators.minLength(6),Validators.maxLength(30)]],
    prenom:['',[Validators.required]],
    site:['',[Validators.required]],
    lien:['',[Validators.required]],
    taux:['',[Validators.required]],
    tauxMoov:['',[Validators.required]],
    tauxOrange:['',[Validators.required]],
    tauxJofe:['',[Validators.required]],
    tauxVisa:['',[Validators.required]]
    //montant:['',[Validators.required]]
  });


  }

  onSubmit(){
    this._apiService.addClient(this.addForm.value.nom,this.addForm.value.prenom,this.addForm.value.mail,this.addForm.value.password,this.addForm.value.numero,this.addForm.value.site,this.addForm.value.lien,this.addForm.value.taux,this.addForm.value.tauxMoov,this.addForm.value.tauxOrange,this.addForm.value.tauxJofe,this.addForm.value.tauxVisa).subscribe((data:Client[])=>{
console.log(data)
this.errorM="Client ajouté avec succès"
setTimeout(()=>{
this.errorM=null;
},2000)
this.router.navigateByUrl('/viewclient')
    })

  }
  back(){
    this.router.navigateByUrl('/viewapi')
  }
  resetF(){
      this.addForm.reset();
  }

}
