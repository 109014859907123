import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ViewapiComponent } from './viewapi/viewapi.component';
import { ApiComponent } from './api/api.component';
import { ApimenuComponent } from './apimenu/apimenu.component';
import { TraceComponent } from './trace/trace.component';
import { LoginComponent } from './login/login.component';
import { PaiementComponent } from './paiement/paiement.component';
import { ClientComponent } from './client/client.component';
import { ViewclientComponent } from './viewclient/viewclient.component';
import { EditclientComponent } from './editclient/editclient.component';
import { PayementsortantComponent } from './payementsortant/payementsortant.component';
import { PaypalComponent } from './paypal/paypal.component';
import { HistoclientComponent } from './histoclient/histoclient.component';
import { RebourComponent } from './rebour/rebour.component';
import { TokengeneratorComponent } from './tokengenerator/tokengenerator.component';
import{SecurisationGuard} from './securisation.guard';
import { FactureGuard } from "./facture.guard";
import { FacturesGuard } from "./factures.guard";
import { CadreComponent } from './cadre/cadre.component';
import { VisaComponent } from './visa/visa.component';
import { EditclientpassComponent } from './editclientpass/editclientpass.component';
import { CardComponent } from './card/card.component';
import { ProfilComponent } from './profil/profil.component';
import { RessetPassComponent } from './resset-pass/resset-pass.component';
import { SonabelComponent } from './sonabel/sonabel.component';
import { SonabelLoginComponent } from './sonabel-login/sonabel-login.component';
import { OneaComponent } from './onea/onea.component';
import { OneaLoginComponent } from './onea-login/onea-login.component';


const routes: Routes = [

  {     path:'api',canActivate:[SecurisationGuard],
        component:ApiComponent
      },
      {     path:'sonabel',canActivate:[FactureGuard],
            component:SonabelComponent
          },
          {     path:'onea',canActivate:[FacturesGuard],
                component:OneaComponent
              },
      {     path:'visa',
            component:VisaComponent
          },
          {     path:'oneaLogin',
                component:OneaLoginComponent

              },
              {     path:'sonabelLogin',
                    component:SonabelLoginComponent
                  },
          {     path:'resset',
                component:RessetPassComponent
              },
          {     path:'profil',
                component:ProfilComponent
              },
          {     path:'card/:site/:montant/:id_commande/:lien',
                component:CardComponent
              },
      {     path:'rebour/:request_id/:lien',
            component:RebourComponent
          },
          {     path:'token',canActivate:[SecurisationGuard],
                component:TokengeneratorComponent
              },
      {     path:'clientTrace',
            component:HistoclientComponent
          },
      {path: 'login', component: LoginComponent},

      {     path:'payementSortant',canActivate:[SecurisationGuard],
            component:PayementsortantComponent
          },

          {     path:'paypal/:montant/:idSite/:id_commande/:lien/:mail',
                component:PaypalComponent
              },

      {     path:'apimenu/:montant/:idSite/:id_commande',
            component:ApimenuComponent
          },
          {     path:'editclient/:id',canActivate:[SecurisationGuard],
                component:EditclientComponent
              },
              {     path:'editclientPassword/:id',canActivate:[SecurisationGuard],
                    component:EditclientpassComponent
                  },
              {     path:'cadre',
                    component:CadreComponent
                  },
          {     path:'traces',canActivate:[SecurisationGuard],
                component:TraceComponent
              },

              {     path:'admin',
                    component:LoginComponent
                  },
                  {     path:'viewclient',canActivate:[SecurisationGuard],
                        component:ViewclientComponent
                      },
                  {     path:'paiement',canActivate:[SecurisationGuard],
                        component:PaiementComponent
                      },
                      {     path:'clients',canActivate:[SecurisationGuard],
                            component:ClientComponent
                          },
//canActivate:[SecurisationGuard]



  {     path:'viewapi',canActivate:[SecurisationGuard],
        component:ViewapiComponent},


        {
          path:'',
          redirectTo:'/admin',
          pathMatch:'full'
        }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
