<div class="container-content">


  <circle-progress *ngIf="loader"
  [percent]="100"
  [radius]="100"
  [outerStrokeWidth]="16"
  [innerStrokeWidth]="4"
  ></circle-progress>
  <circle-progress *ngIf="loader2"
  [percent]="100"
  [radius]="100"
  [outerStrokeWidth]="16"
  [innerStrokeWidth]="4"
  ></circle-progress>
  <div *ngIf="aff==0" class="quit">
    <button type="button"
            name="button"
            class="btn btn-danger"
            (click)="quit()">Retour</button>
  </div>
    <div  *ngIf="affich==0" class="entrer" >
      <div class="alert alert-danger" *ngIf="errorM">
        {{errorM}}
      </div>


      <body>
      <div id="login">
          <h3 class="text-center text-white pt-5">Bienvenue sur l'espace client de jpayafrica</h3>
          <div class="container">
              <div id="login-row" class="row justify-content-center align-items-center">
                  <div id="login-column" class="col-md-6">
                      <div id="login-box" class="col-md-12">
                          <form  [formGroup]="addForm" id="login-form" class="form" >
                              <h3 class="text-center text-info">Connexion</h3>
                              <div class="form-group">
                                  <label for="mail" class="text-info">Adresse mail:</label><br>
                                  <input type="email" name="mail" id="username" class="form-control" formControlName="mail">
                              </div>
                              <div class="form-group">
                                  <label for="password" class="text-info">Mot de passe:</label><br>
                                  <input type="password" name="password" id="password" class="form-control" formControlName="password">
                              </div>
                              <div class="form-group">
                                  <label for="site" class="text-info">Nom du site:</label><br>
                                  <input type="text" name="site" id="site" class="form-control" formControlName="site">
                              </div>
                              <div class="form-group">
                                  <button type="submit"
                                          class="btn btn-primary btn-block"
                                          [disabled]="addForm.invalid"
                                          (click)="onSubmit()"

                                  > Soumettre  </button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      </body>


      <!--<form [formGroup]="addForm">


        <div class="form-group input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"> <i class="fa fa-envelope"></i> </span>
         </div>
            <input name="mail" formControlName="mail" class="form-control" placeholder="Adresse mail" type="email">
        </div>

        <div class="form-group input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
        </div>
            <input name="password" formControlName="password" class="form-control" placeholder="Mot de passe" type="password">
        </div>
        <div class="form-group input-group">
          <div class="input-group-prepend">
              <span class="input-group-text"> <i class="fa fa-web"></i> </span>
           </div>
              <input name="site" formControlName="site" class="form-control" placeholder="Nom du site" type="text">
          </div>
<br>
        <div class="form-group">
            <button type="submit"
                    class="btn btn-primary btn-block"
                    [disabled]="addForm.invalid"
                    (click)="onSubmit()"

            > Soumettre  </button>
        </div>

      </form>-->



    </div>
    <a routerLink="/profil" >
    <div  class="user-photo"  *ngIf="profil">
      <img  src="assets/images/unnamed.png" alt="user photo"/>
    </div>
    </a>
    <div *ngIf="affiche==1" class="result">
      <h2 *ngIf="traces.length">Historique des paiements sur {{traces[0].id_site}}</h2><br>
      <h2 *ngIf="!traces.length">Aucun paiement pour ce site ou informations incorrectes</h2>

      <div class="col" style="float:right;">

  </div>
      <div *ngIf="aff==1" class="tab">

        <div style="height: calc(80% - 86px)"><br><br>
                        <ejs-grid [dataSource]='traces' #grid [allowPaging]='true' [allowSorting]='true' [editSettings]="editSetting"
                                  [filterSettings]='filterSettings' [allowFiltering]='true' [allowGrouping]="false"

                                  height="100%">

                            <e-columns>

                            <e-column field='id' headerText='#' textAlign='left' width='150'></e-column>


                                <e-column field='numero' headerText='Numero' textAlign='left' width='150'></e-column>

                                <e-column field='id_commande' headerText='Id_command' textAlign='left' width='150'></e-column>


                                <e-column field='montant' headerText='Montant' textAlign='left' width='150'></e-column>


                                <e-column field='CommissionOperateur' headerText='CommissionOperateur' textAlign='left' width='210'></e-column>
                                <e-column field='CommissionJofe' headerText='CommissionJofe' textAlign='left' width='210'></e-column>
                                <e-column field='TTC' headerText='TTC' textAlign='left' width='150'></e-column>
                                <e-column field='api' headerText='Api' textAlign='left' width='150'></e-column>
                                <e-column field="date"  headerText='Date' textAlign='left' width='150'></e-column>
                                <e-column field="heure"  headerText='Heure' textAlign='left' width='150'></e-column>






                            </e-columns>

                        </ejs-grid>
                        <br>
                        <br>

                        <div class="row">
                          <div class="col">
                            <h3>Total :{{total}} FCFA</h3>
                          </div>
                          <div class="col">
                            <h3>TTC :{{ttc}} FCFA</h3>
                          </div>
                          
                        </div><br>
                    </div>
      <!--  <clr-datagrid>
          <clr-dg-column> #</clr-dg-column>
          <clr-dg-column> Numero</clr-dg-column>
          <clr-dg-column>Id_commande</clr-dg-column>
          <clr-dg-column> Montant</clr-dg-column>
          <clr-dg-column>CommissionOperateur</clr-dg-column>
          <clr-dg-column>CommissionJofe</clr-dg-column>
          <clr-dg-column>TTC</clr-dg-column>
            <clr-dg-column>Moyen de paiement</clr-dg-column>
            <clr-dg-column>Date</clr-dg-column>
              <clr-dg-column>Heure</clr-dg-column>

          <clr-dg-row *clrDgItems="let trace of traces">

            <clr-dg-cell>{{trace.id}}</clr-dg-cell>
            <clr-dg-cell>{{trace.numero}}</clr-dg-cell>
            <clr-dg-cell>{{trace.id_commande}}</clr-dg-cell>
              <clr-dg-cell>{{trace.montant}}</clr-dg-cell>
              <clr-dg-cell >{{(trace.montant*(trace.tauxOperateur/100)).toFixed(2)}}</clr-dg-cell>
              <clr-dg-cell >{{(trace.montant*(trace.tauxJofe/100)).toFixed(2)}}</clr-dg-cell>
              <clr-dg-cell>{{(trace.montant-(trace.montant*(trace.tauxOperateur/100)+trace.montant*(trace.tauxJofe/100))).toFixed(2)}}</clr-dg-cell>
            <clr-dg-cell>{{trace.api}}</clr-dg-cell>
            <clr-dg-cell>{{trace.created_at | date:'dd/MM/yyyy'}} </clr-dg-cell>
            <clr-dg-cell>{{trace.created_at | date:'H:mm'}} </clr-dg-cell>

          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
              <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Historiques par page</clr-dg-page-size>
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} des {{pagination.totalItems}} historiques
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>-->

      </div>
  <!--  <td><h3>Recherche par</h3>

            <select [(ngModel)]="selectedOption">
       <option *ngFor="let o of options">
          {{o.name}}
       </option>
    </select></td>

    <td *ngIf="selectedOption=='Id_commande'"><form [formGroup]="addForm1" novalidate class="form">
      <div class="alert alert-danger"
      *ngIf="addForm1.get('idcommande').hasError('required') && addForm1.get('idcommande').touched">
    Idcommande  obligatoire
      </div>

           <table>
             <tr>
               <td >  <div class="ml1">
                 <div class="form-group">

                   <input type="text"
                   class="form-control"

                    placeholder="Entrez l'id de la commande"
                    formControlName="idcommande"
                     name="idcommande"

                   >
                   </div>
               </div></td><td><div class="bt1">
                 <div class="form-group">
                            <button type="button"
                                         class="btn btn-success "
                                         (click)="findByIdcommande()">
                                         <i class="fas fa-mobile-alt mr-2">
                                       Valider</i>
                                  </button>
                         </div>
               </div>  </td>
             </tr>
           </table>

           <div class="alert alert-danger" *ngIf="errorM1">
             {{errorM1}}
           </div>
           <div class="alert alert-danger" *ngIf="errorM2">
             {{errorM2}}
           </div>
      </form>

       <div *ngIf="selectedOption=='Numero de téléohone'">
        <form [formGroup]="addForm2" novalidate class="form">
        <div class="alert alert-danger"
        *ngIf="addForm2.get('numero').hasError('required') && addForm2.get('numero').touched">
      Numero  obligatoire
        </div>



               <div class="ml2">
                   <div class="form-group">

                     <input  type="text"
                     class="form-control"

                      placeholder="Numero de la transaction"
                      formControlName="numero"
                       name="numero"

                     >
                     </div>
                 </div><div class="bt2">
                   <div class="form-group">
                              <button type="button"
                                           class="btn btn-success "
                                           (click)="findByINumero()">
                                           <i class="fas fa-mobile-alt mr-2">
                                         Valider</i>
                                    </button>
                           </div>
                 </div>



        </form></div>




      <br>



    </div>
    <!--<div *ngIf="affich1==1 && !loader2" class="tab1">
      <clr-datagrid>
        <clr-dg-column> #</clr-dg-column>
        <clr-dg-column> Numero</clr-dg-column>
        <clr-dg-column>Id_commande</clr-dg-column>
        <clr-dg-column> Montant</clr-dg-column>
        <clr-dg-column> Taxe</clr-dg-column>
          <clr-dg-column>TTC</clr-dg-column>
          <clr-dg-column>Moyen de paiement</clr-dg-column>
          <clr-dg-column>Date</clr-dg-column>

        <clr-dg-row *clrDgItems="let trace of traces1">

          <clr-dg-cell>{{trace.id}}</clr-dg-cell>
          <clr-dg-cell>{{trace.numero}}</clr-dg-cell>
          <clr-dg-cell>{{trace.id_commande}}</clr-dg-cell>
            <clr-dg-cell>{{trace.montant}}</clr-dg-cell>
          <clr-dg-cell>{{(trace.montant*(trace.taux/100)).toFixed(2)}}</clr-dg-cell>
          <clr-dg-cell>{{(trace.montant-(trace.montant*(trace.taux/100))).toFixed(2)}}</clr-dg-cell>
          <clr-dg-cell>{{trace.api}}</clr-dg-cell>
          <clr-dg-cell>{{trace.created_at | date:'dd/MM/yyyy à H:mm'}} </clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-pagination #pagination [clrDgPageSize]="10">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Historiques par page</clr-dg-page-size>
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} des {{pagination.totalItems}} historiques
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>

    </div>-->

  <!--  <div *ngIf="affich2==1 && !loader" class="tab2">
      <clr-datagrid>
        <clr-dg-column> #</clr-dg-column>
        <clr-dg-column> Numero</clr-dg-column>
        <clr-dg-column>Id_commande</clr-dg-column>
        <clr-dg-column> Montant</clr-dg-column>
        <clr-dg-column> Taxe</clr-dg-column>
          <clr-dg-column>TTC</clr-dg-column>
          <clr-dg-column>Moyen de paiement</clr-dg-column>
          <clr-dg-column>Date</clr-dg-column>

        <clr-dg-row *clrDgItems="let trace of traces2">

          <clr-dg-cell>{{trace.id}}</clr-dg-cell>
          <clr-dg-cell>{{trace.numero}}</clr-dg-cell>
          <clr-dg-cell>{{trace.id_commande}}</clr-dg-cell>
            <clr-dg-cell>{{trace.montant}}</clr-dg-cell>
          <clr-dg-cell>{{(trace.montant*(trace.taux/100)).toFixed(2)}}</clr-dg-cell>
          <clr-dg-cell>{{(trace.montant-(trace.montant*(trace.taux/100))).toFixed(2)}}</clr-dg-cell>
          <clr-dg-cell>{{trace.api}}</clr-dg-cell>
          <clr-dg-cell>{{trace.created_at | date:'dd/MM/yyyy à H:mm'}} </clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-pagination #pagination [clrDgPageSize]="10">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Historiques par page</clr-dg-page-size>
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} des {{pagination.totalItems}} historiques
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>

    </div>-->
  </div>
