import {Injectable, PipeTransform} from '@angular/core';
import {from} from 'rxjs';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import{Trace} from './trace';
import axios from "axios";
import{Client} from './client';
import { RequestOptions } from '@angular/http';
import{Api} from './api';
import{HttpClient,HttpHeaders,HttpRequest} from '@angular/common/http';


import { API_URLS } from "./config/api.url.config";
import { Component, OnInit } from '@angular/core';

//import { map, filter, switchMap } from 'rxjs/operators';
import { map } from "rxjs/operators";



@Injectable({
  providedIn: 'root'
})

export class ApiService {

rsp:any

paymentToken:any
responseMessage:string
isAth:boolean=false;
isAthSonabel:boolean=false;
isAthOnea:boolean=false;
taux:number
USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'

  public username: String;
  public password: String;
  request: HttpRequest<any>
 token:string='';

  constructor(private http: HttpClient) {
    this.token="Bearer eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJzb2Z0dGVrSldUIiwic3ViIjoib3VlZHJhb2dvYXJpc0BnbWFpbC5jb20yODg1MzUxb3VlZHJhb2dvYXJpc0BnbWFpbC5jb20yODg1MzUxb3VlZHJhb2dvYXJpc0BnbWFpbC5jb20yODg1MzUxb3VlZHJhb2dvYXJpc0BnbWFpbC5jb20yODg1MzUxb3VlZHJhb2dvYXJpc0BnbWFpbC5jb20yODg1MzUxIiwiYXV0aG9yaXRpZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzMjIzMzY3N30.0AdgwHtGOG79hybSRUlH-nUNZ3jwAADD7GianLOlJkU"
  //this.token = 'Basic ' + window.btoa("JofeDigital" + ":" + "288535122Jofe@");

  }
  cardPayement(body:any,token:any){

    let  headers= new HttpHeaders().append('Content-Type', 'application/vnd.ni-payment.v2+json')
    .append('Authorization', 'Bearer '+token)
    .append('Accept', 'application/vnd.ni-payment.v2+json')

    let options = { headers: headers };

     const url="https://api-gateway.orabankbf.ngenius-payments.com/transactions/outlets/14e4b11f-ed9d-464f-a707-204af41ed336/payment/card"




     //console.log(JSON.stringify(body))
     return this.http.post(url,JSON.stringify(body),options);
  }

validCardPayement(acsMd:any,acsPaReq:any,acsUrl:any){

    //let headers = new HttpHeaders().append('Content-Type', 'application/json');

    const body = JSON.stringify(
      { PaReq:acsPaReq,
        MD:acsMd,
        TermUrl:'https://jofedigital.com',
        acsUrl:acsUrl

     });
     const options = {
  responseType: 'text'
};
      const headers = new HttpHeaders().append('Content-Type', 'text/plain');
     console.log(body)
  return new Promise(
      (resolve,reject)=>{

      this.http.post(acsUrl,body,{

    responseType: 'text'
}).subscribe(
         (data:any)=>{
           console.log(data)
           resolve(data)
     },(error)=>{
       console.log(error)
                   reject(false)
         }
       )
      }
    )



                    /*let headers = new HttpHeaders({
                    'Content-Type' : 'application/json'

                      });

  const body = JSON.stringify(
    { PaReq:acsPaReq,
      MD:acsMd,
      TermUrl:'https://jofedigital.com'
   });

   console.log(body)
   return this.http.post(acsUrl,body,{responseType: "text"});*/
}
  ressetPassClient(mail:any,password:any,new_password:any,site:any){
    let headers= new HttpHeaders({

                          'Content-Type' : 'application/json; charset=utf-8',
                          'Accept'       : 'application/json',
                          Authorization:this.token

                      })

            const body = JSON.stringify(
              { password:password,
                site:site,
                mail:mail,
                new_password:new_password
             });
         return this.http.post(API_URLS.lien+'/ressetPassword',
        body,{
          headers:headers
        });
  }
  access_token(){

  /*let  headers= new HttpHeaders()
  .append('Content-Type', 'application/vnd.ni-identity.v1+json')
  .append('Authorization', 'Basic MTE5N2EwZTAtMGRjYy00NzA0LWI4YzYtYWZkNDBmNzYxZmI3OmRhNDU5NTIwLTc1M2MtNGE2MS1iN2Q5LTJjMWY5OGFkYTMyMg==')
*/
 /*let headers = new HttpHeaders(
   { "Content-Type": "application/vnd.ni-identity.v1+json" ,
   'Authorization': 'Basic MTE5N2EwZTAtMGRjYy00NzA0LWI4YzYtYWZkNDBmNzYxZmI3OmRhNDU5NTIwLTc1M2MtNGE2MS1iN2Q5LTJjMWY5OGFkYTMyMg=='

});*/

   //let options = { headers: headers,observe:"response" };

    const url="https://card.jofedigital.com/api/gentoken"



    //const body = JSON.stringify({realmName : "OBBurkinaSandbox"});
    //console.log(body)
    //console.log(options)
    return this.http.get(url)

  }
    getApi(){

 const headers=new HttpHeaders({Authorization:this.token})
    return this.http.get(API_URLS.Api_URLS,{headers:headers});
    }

  /*  initApi(){

 const headers=new HttpHeaders({Authorization:this.token})
    return this.http.get(API_URLS.intApi_Url,{headers:headers});
  }*/

    getTrace(){
     const headers=new HttpHeaders({Authorization:this.token})
      return this.http.get(API_URLS.Trace_URLS,{headers:headers}
        );
    }
    getCopytrace(){

      const headers=new HttpHeaders({Authorization:this.token})
      return this.http.get(API_URLS.CopyTrace_URLS,{headers:headers});
    }

    getTracePayee(){
      const headers=new HttpHeaders({Authorization:this.token})
        return this.http.get(API_URLS.CopyTracePayee,{headers:headers});
    }
cardTrace(data:any){
  let headers= new HttpHeaders({

                        'Content-Type' : 'application/json; charset=utf-8',
                        'Accept'       : 'application/json',
                        Authorization:this.token


                    })
    //const headers=new HttpHeaders({Authorization:'Basic'+btoa(username+":"+password)})
      const body = JSON.stringify(data);
   return this.http.post(API_URLS.lien+'/cardTrace',
  body,{
    headers:headers
  });
}

cardExterne(data:any){
  const url='https://visa.jofedigital.com'
  let headers= new HttpHeaders({

                        'Content-Type' : 'application/json; charset=utf-8',
                        'Accept'       : 'application/json'
                        //Authorization:this.token


                    })
    //const headers=new HttpHeaders({Authorization:'Basic'+btoa(username+":"+password)})
      const body = JSON.stringify(data);
   return this.http.post(url,
  body,{
    headers:headers
  });
}

paypalTrace(site:string,mail:string,amount:number,id_commande:string){
  let headers= new HttpHeaders({

                        'Content-Type' : 'application/json; charset=utf-8',
                        'Accept'       : 'application/json',
                        Authorization:this.token


                    })
    //const headers=new HttpHeaders({Authorization:'Basic'+btoa(username+":"+password)})
      const body = JSON.stringify(
        {
         site:site,
         id_commande:id_commande,
         mail:mail,
         amount:amount
       });
   return this.http.post(API_URLS.lien+'/CopyTracePayee',
  body,{
    headers:headers
  });
}

    getTracePayeeBySite(site:string){
    /*  let username="joFediGital"
      let password="J0fedigit@lp@ss288535122"*/
      // let headers=this.request.headers.set('Authorization',this.token )

      let headers= new HttpHeaders({

                            'Content-Type' : 'application/json; charset=utf-8',
                            'Accept'       : 'application/json',
                            Authorization:this.token


                        })
        //const headers=new HttpHeaders({Authorization:'Basic'+btoa(username+":"+password)})
          const body = JSON.stringify(
            {
             site:site
           });
       return this.http.post(API_URLS.lien+'/CopyTracePayee',
      body,{
        headers:headers
      });
    }

    getCopyBySite(site:string){

let headers= new HttpHeaders({

                      'Content-Type' : 'application/json; charset=utf-8',
                      'Accept'       : 'application/json',
                      Authorization:this.token


                  })



          const body = JSON.stringify(
            {
             site:site
           });
       return this.http.post(API_URLS.lien+'/traceBySite',
      body,{
        headers:headers
      });
    }
updateClientPassword(password:string,id:number){
  let headers= new HttpHeaders({

                        'Content-Type' : 'application/json; charset=utf-8',
                        'Accept'       : 'application/json',
                        Authorization:this.token

                    })

          const body = JSON.stringify(
            {password:password,

             id:id
           });
       return this.http.post(API_URLS.lien+'/upclientPassword',
      body,{
        headers:headers
      });
}

    getClientTrace(mail:string,password:string,site:string){

       let headers= new HttpHeaders({

                      'Content-Type' : 'application/json; charset=utf-8',
                      'Accept'       : 'application/json',
                      Authorization:this.token


                  })



          const body = JSON.stringify(
            {
             mail:mail,
             password:password,
             site:site
           });
       return this.http.post(API_URLS.lien+'/clientTrace',
      body,{
        headers:headers
      });
    }
getClientTraceByIdcommande(idcommande:string,site:string){
  let headers= new HttpHeaders({

                        'Content-Type' : 'application/json; charset=utf-8',
                        'Accept'       : 'application/json',
                        Authorization:this.token


                    })



            const body = JSON.stringify(
              {
               idcommande:idcommande,
               site:site
             });
         return this.http.post(API_URLS.lien+'/clientTraceIdcommande',
        body,{
          headers:headers
        });
}
getClientTraceByNumero(numero:string,site:string){
  let headers= new HttpHeaders({

                        'Content-Type' : 'application/json; charset=utf-8',
                        'Accept'       : 'application/json',
                        Authorization:this.token


                    })



            const body = JSON.stringify(
              {
               numero:numero,
               site:site
             });
         return this.http.post(API_URLS.lien+'/clientTraceNumero',
        body,{
          headers:headers
        });
}

    getPayementSortantBySite(site:string){



      let headers= new HttpHeaders({

                            'Content-Type' : 'application/json; charset=utf-8',
                            'Accept'       : 'application/json',
                            Authorization:this.token

                        })

          const body = JSON.stringify(
            {
             site:site
           });
       return this.http.post(API_URLS.lien+'/payementSortantBySite',
      body,{
        headers:headers
      });
    }
deleteClient(id:number){
   const headers=new HttpHeaders({Authorization:this.token})
  return this.http.delete<Client[]>(API_URLS.lien+"/client/"+ id,{headers:headers});

}
updateClient(nom:string,prenom:string,email:string,telephone:string,site:string,lien:string,taux:number,tauxMoov:number,tauxOrange:number,tauxJofe:number,tauxVisa:number,id:number){

  let headers= new HttpHeaders({

                        'Content-Type' : 'application/json; charset=utf-8',
                        'Accept'       : 'application/json',
                        Authorization:this.token

                    })

          const body = JSON.stringify(
            {nom:nom,
              prenom:prenom,
              email:email,
              telephone:telephone,
             site:site,
             lien:lien,
             taux:taux,
             tauxMoov:tauxMoov,
             tauxOrange:tauxOrange,
             tauxJofe:tauxJofe,
             tauxVisa:tauxVisa,
             id:id
           });
           //console.log(body)
       return this.http.post(API_URLS.lien+'/upclient',
      body,{
        headers:headers
      });
}
getClientById(id:number){
   const headers=new HttpHeaders({Authorization:this.token})
   return this.http.get<Client[]>(API_URLS.lien+"/clients/" + id,{headers:headers});

}
    getClientBySite(site:string){

      let headers= new HttpHeaders({

                            'Content-Type' : 'application/json; charset=utf-8',
                            'Accept'       : 'application/json',
                            Authorization:this.token

                        })
          const body = JSON.stringify(
            {
             site:site
           });
       return this.http.post(API_URLS.lien+'/clientBySite',
      body,{
        headers:headers
      });
    }



    initAdmin(){

      let headers= new HttpHeaders({

                            'Content-Type' : 'application/json; charset=utf-8',
                            'Accept'       : 'application/json',
                            Authorization:this.token

                        })

       return this.http.get(API_URLS.lien+'/initAdmin',
      {
        headers:headers
      });
    }

getLientBySite(site:string){

  let headers= new HttpHeaders({

                        'Content-Type' : 'application/json; charset=utf-8',
                        'Accept'       : 'application/json',
                        Authorization:this.token

                    })
      const body = JSON.stringify(
        {
         site:site
       });
   return this.http.post(API_URLS.lien+'/lienBySite',
  body,{
    headers:headers
  });

}
    sendMail(mail:string,id_commande:string,montant:string){

      let headers= new HttpHeaders({

                            'Content-Type' : 'application/json; charset=utf-8',
                            'Accept'       : 'application/json',
                            Authorization:this.token

                        })

          const body = JSON.stringify(
            {
             mail:mail,
             id_commande:id_commande,
             montant:montant
           });
       return this.http.post(API_URLS.lien+'/sendMail',
      body,{
        headers:headers
      });
    }
addClient(nom:string,prenom:string,mail:string,password:string,numero:string,site:string,lien:string,taux:number,tauxMoov:number,tauxOrange:number,tauxJofe:number,tauxVisa:number){


  let headers= new HttpHeaders({

                        'Content-Type' : 'application/json; charset=utf-8',
                        'Accept'       : 'application/json',
                        Authorization:this.token

                    })

      const body = JSON.stringify(
        {
         mail:mail,
         nom:nom,
         password:password,
         prenom:prenom,
         site:site,
         numero:numero,
         lien:lien,
         taux:taux,
         tauxMoov:tauxMoov,
         tauxOrange:tauxOrange,
         tauxJofe:tauxJofe,
         tauxVisa:tauxVisa
       });
   return this.http.post(API_URLS.lien+'/saveClient',
  body,{
    headers:headers
  });
}
    deleteBySite(site:string){

      let headers= new HttpHeaders({
                            'Content-Type': 'application/json',
                            Authorization:this.token

                        })

          const body = JSON.stringify(
            {
             site:site
           });
       return this.http.post(API_URLS.lien+'/deleteBySite',
      body,{
        headers:headers
      });
    }

    createApi(api:Api){
      return this.http.post(API_URLS.Create_urls,api);
    }

    httpOptions = {
      headers: new HttpHeaders({
  'Content-Type':  'application/json'
})
};

    sendPostRequest(mail:string,id_commande:string,phone:string,otp:string,montant:number,url:string,site:string,api:string){

      let headers= new HttpHeaders({
                            'Content-Type': 'application/json',
                            Authorization:this.token

                        })

          const body = JSON.stringify(
            {
              mail:mail,
              id_commande:id_commande,
              phone: phone,
             otp:otp,
             montant:montant,
             url:url,
             site:site,
             api:api,
             mode:'production'
           });
       return this.http.post(API_URLS.lien+'/payment',
      body,{
        headers:headers
      });
    }


    authenticateOnea(email:string,mdp:string){

      let headers= new HttpHeaders({
                            'Content-Type': 'application/json',
                            Authorization:this.token

                        })

          const body = JSON.stringify(
            {username: email,
             mdp:mdp,
             nom:"onea"

           });
console.log(body)



                return this.http.post(API_URLS.lien+'/authentifications',
                  body,{
                    headers:headers
                  })




    }

    getSonabel(){
      const headers=new HttpHeaders({Authorization:this.token})
       return this.http.get(API_URLS.Sonabel_URLS,{headers:headers}
         );
    }
    getOnea(){
      const headers=new HttpHeaders({Authorization:this.token})
       return this.http.get(API_URLS.Onea_URLS,{headers:headers}
         );
    }

    authenticateSonabel(email:string,mdp:string){

      let headers= new HttpHeaders({
                            'Content-Type': 'application/json',
                            Authorization:this.token

                        })

          const body = JSON.stringify(
            {username: email,
             mdp:mdp,
             nom:"sonabel"

           });
console.log(body)



                return this.http.post(API_URLS.lien+'/authentifications',
                  body,{
                    headers:headers
                  })




    }




        access(id:number){

          let headers= new HttpHeaders({
                                'Content-Type': 'application/json',
                                Authorization:this.token})

              const body = JSON.stringify({id: id });

              return this.http.post(API_URLS.lien+'/clientToken',body,{headers:headers })
        }

    testRedirection(){

      let headers= new HttpHeaders({
                            'Content-Type': 'application/json',
                            Authorization:this.token



                        })
                        //const headers = new HttpHeaders().append('Content-Type', 'text/plain');

          const body = JSON.stringify(
            {mail: "email",
             montant:500,
             site:"sonabel",
             id_commande:"test"

           });
  //console.log(body)



                return this.http.post(API_URLS.lien+'/checkcard',
                  body,{

                responseType: 'text',
                headers:headers
            })




    }



    authenticates(email:string,mdp:string){

      let headers= new HttpHeaders({
                            'Content-Type': 'application/json',
                            Authorization:this.token

                        })

          const body = JSON.stringify(
            {username: email,
             mdp:mdp,

           });




                return this.http.post(API_URLS.lien+'/authentification',
                  body,{
                    headers:headers
                  })




    }
    sendPostRequestMoov(phone:string,montant:number,message:string,remarks:string,request_id:string,site:string,mail:string,id_commande:string,api:string){


     let headers= new HttpHeaders({
                            'Content-Type': 'application/json',
                            Authorization:this.token

                        })

          const body = JSON.stringify(
            {
             "request-id":request_id,
             "destination": phone,
             "amount":montant,
             "remarks":remarks,
             "message":message,
             "extended-data":{},
             site:site,
             mail:mail,
             id_commande:id_commande,
             api:api,
             mode:'production'
           });
           console.log(body)
       return this.http.post(API_URLS.lien+'/paymentMoov',
      body,{
        headers:headers
      });

    }

    Check_transaction_status(request_id:string){


     let headers= new HttpHeaders({
                            'Content-Type': 'application/json',
                            Authorization:this.token

                        })

          const body = JSON.stringify(
            {
               "request-id":request_id,
                mode:'production'

           });
           //console.log(body)
       return this.http.post(API_URLS.lien+'/checkstatus',
      body,{
        headers:headers
      })

    }

    conversion(){
      const headers = new HttpHeaders();

    return this.http.get("http://apilayer.net/api/live?access_key=3ff4afdd9ced85b45578fd789b84a28d")

    }


    getToken(username:string,password:string){

     var url=API_URLS.tokengenerator
     let formData : any=new FormData()
   formData.append("user",username)
   formData.append("password",password)
   return this.http.post(url,formData)
    }
    getApiById(id:number){
      return this.http.get<Api[]>(API_URLS.GetById_url + id);
    }
    updateApi(api:Api){

      return this.http.put(API_URLS.Update_url + '=' + api.id,api);
    }
    deleteApi(id:number){
  return this.http.delete<Api[]>(API_URLS.Delete_url+'='+ id);

}
}
