
<div class="container-fluid"  >

<div class="contenu" [style.background-image]="'url(./assets/images/jp.png )'">


  <h5 class="totaltext">
  Total à payer :  {{total}} Fcfa </h5>
<br><br>
<h3 class="tx"><i>
      Choisissez le mode de paiement</i></h3>
  <circle-progress *ngIf="loderOrange"
   [percent]="100"
  [radius]="30"
   [outerStrokeWidth]="4"
   [innerStrokeWidth]="2"

></circle-progress>
<br>
<div class=" menu">
<div class="row">
  <div class="col">
    <button class="moov "
           type="button"
           name="button"
           (click)="Mobic()" >
    <img [src]="moov"
    class="mobil_image4"/>
  </button>
  </div>
  <div class="col">
    <button class="moov "
           type="button"
           name="button"
           (click)="cardPayment()" >
    <img [src]="visa_image"
    class="mobil_image4"/>
  </button>
  </div>
  <div class="col">
    <button class="moov "
     type="button"
     name="button"
     (click)="Mobils()">
     <img [src]="mobil_image"
     class="mobil_image4"/>
   </button>
  </div>
</div>






</div>
  <div *ngIf="bolPaypal=='s' " class="alert alert-success" role="alert" id="sp">
  {{messagePaypal}}
</div>
    <div *ngIf="bol=='s' " class="alert alert-success" role="alert" id="s">
    {{Message_type}}
  </div>
  <div *ngIf="bol=='e'" class="alert alert-danger" role="alert" id="i">
    {{Message_type}}
  </div>

  <div *ngIf="bolCard=='e'" class="alert alert-danger" role="alert" id="iCard">
    {{Message_typeCard}}
  </div>
  <div *ngIf="bolCard=='s'" class="alert alert-success" role="alert" id="iCards">
    {{Message_typeCard}}
  </div>
<div *ngIf="Mobil==1 && Carte==0 && Paypal==0 && Mobi==0;" id="net-banking" class="mobilClass">

<form [formGroup]="addForm" novalidate class="form">

          <div class="alert alert-danger"
   *ngIf="addForm.get('numero').hasError('required') && addForm.get('numero').touched">
       Votre numero  est obligatoire
   </div>
   <div class="alert alert-danger" *ngIf="addForm.get('numero').hasError('pattern')">
       Veuillez respecter le format :11111111 </div>
          <div class="form-group">
            <label style="color:black;" for="exampleFormControlInput1">Numero</label>
            <input type="text"
            class="form-control"
             id="exampleFormControlInput1"
             placeholder="Entrez votre numero"
             formControlName="numero"
              name="numero"
              pattern="^\d{2}\d{2}\d{2}\d{2}$">
          </div>
          <div class="alert alert-danger"
*ngIf="addForm.get('otp').hasError('required') && addForm.get('otp').touched">
Le otp est obligatoire
</div>
<div class="alert alert-danger" *ngIf="addForm.get('otp').hasError('pattern')">
Veuillez respecter le format :111111 </div>
          <div class="form-group">
            <label style="color:black;"  for="exampleFormControlInput1">OTP (Tapez *144*4*6*{{total}}# sur votre téléphone)</label>
            <input type="text"
             class="form-control"

             placeholder="Entrez le OTP "
             pattern="^\d{2}\d{2}\d{2}$"
             formControlName="otp"
              name="otp">
          </div>


        <div class="les">
          <tr >
            <td> <button type="button"
                          class="btn btn-success "
                          (click)="onSubmit()">

                          Payez
                   </button></td>
            <td><button type="button"
                    class="btn btn-danger "
                      (click)="resetF()">


                         Annuler
                  </button></td>
          </tr>
        </div>
    </form>


</div>

<div *ngIf="Mobi==1 && Carte==0 && Paypal==0 && Mobil==0;"  class="mobilClass">

<form [formGroup]="addFormMoov" novalidate class="form">

      <div class="alert alert-danger"
*ngIf="addFormMoov.get('numero').hasError('required') && addFormMoov.get('numero').touched">
   Votre numero  est obligatoire
</div>
<div class="alert alert-danger" *ngIf="addFormMoov.get('numero').hasError('pattern')">
Veuillez respecter le format :11111111 </div>
      <div class="form-group">
        <label style="color:black;"  for="exampleFormControlInput1">Numero</label>
        <input type="text"
        class="form-control"
         id="exampleFormControlInput1"
         placeholder="Entrez votre numero"
         formControlName="numero"
          name="numero"
          pattern="^\d{2}\d{2}\d{2}\d{2}$">
      </div>

<div class="les">
  <tr >
    <td> <button type="button"
                  class="btn btn-success "
                  (click)="onSubmitMoov()">

                  Payez
           </button></td>
    <td><button type="button"
            class="btn btn-danger "
              (click)="resetFMoov()">


                 Annuler
          </button></td>
  </tr>
</div>
</form>

</div>

</div>


</div>
