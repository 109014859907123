import {DecimalPipe} from '@angular/common';
import {Component, QueryList, ViewChildren} from '@angular/core';
import {Observable} from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap' ;
//import {Country} from './country';
//import {CountryService} from './country.service';
import {SortableDirective, SortEvent} from '../sortable.directive';

import { OnInit } from '@angular/core';
import{Trace} from '../trace';
import{ApiService} from '../api.service';
import{TraceserviceService} from '../traceservice.service';

@Component({
  selector: 'app-trace',
  templateUrl: './trace.component.html',
  styleUrls: ['./trace.component.css']
})
export class TraceComponent implements OnInit {
  traces$: Observable<Trace[]>;
  total$: Observable<number>;
  traces:Trace[];

  _id:number;
  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;

  constructor(private dt :DatePipe,private _apiService:ApiService,public _traceService:TraceserviceService) {
    //this.traces = _traceService.trace_s;
    /*for (let index = 0; index < this.traces$.length; index++) {
    this.traces$.created_at =dt.transform(this.traces$.created_at, 'yyyy-MM-dd,H:mm');
  }*/
}

  ngOnInit(){

  this.loadTrace();


  }
  /*onSort({column, direction}: SortEvent) {
      // resetting other headers
      this.headers.forEach(header => {
        if (header.sortable !== column) {
          header.direction = '';
        }
      });

      this._traceService.sortColumn = column;
      this._traceService.sortDirection = direction;
    }*/
  loadTrace(){
  this._apiService.getTrace().subscribe((data:Trace[])=>
  {this.traces=data
  },
  error => {console.log('erreur')},
  () => {console.log('traces chargées avec succès')}

  );
  }


}
