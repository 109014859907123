
  <clr-main-container>
        <clr-header>

          <div class="header-nav" [clr-nav-level]="1">
            <div class="branding text-center">JPAY Administration</div>
          </div>

        </clr-header>

        <div class="content-container">
          <main class="content-area" >
            <br><br><br><br>

                  <!-- breadcrumb-section end -->
                  <!-- product tab start -->
                  <div class="register pt-80 pb-80">
                      <div class="container">
                          <div class="row">
                              <div class="col-12">

                                  <div class="log-in-form" [formGroup] *ngIf="!bol">

                                      <form class="personal-information" [formGroup]="registerForm">


              <h2 class="title text-dark text-capitalize">Generation de token</h2><br>



                                          <div class="form-group row text-center">
                                              <label for="email" class="col-md-3 col-form-label">Username</label>
                                              <div class="col-md-6">
                                                  <input formControlName="email" name="email" type="text" id="email" class="form-control">
                                              </div>
                                          </div>

                                          <div class="form-group row text-center">
                                              <label for="password" class="col-md-3 col-form-label">Password</label>
                                              <div class="col-md-6">
                                                  <div class="input-group mb-2 mr-sm-2">
                                                      <input  formControlName="password" name="password" type="password" class="form-control" id="Password">

                                                  </div>
                                              </div>
                                          </div>


                                         <div class="form-group row pb-3 text-center">

                                               <div class="sign-btn">
                                                 <button (click)="onSubmit()" class="btn theme-btn-dark1 btn--md" type="button" name="button" [disabled]="registerForm.invalid"> Generer</button>
                                               </div>



                                         </div>
                                      </form>
                                  </div>
                                  <div class="" *ngIf="bol">
                                    <h2>Votre token d'acces à l'api </h2>

                                    <textarea name="name" rows="8" cols="80" value={{token}} disabled ></textarea><br><br><br>
                                    <button (click)="copyInputMessage(token)" value="Copier" class="btn btn-primary" >Copier</button>

                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

          </main>
          <nav class="sidenav" [clr-nav-level]="2">
           <section class="sidenav-content">
             <a routerLink="/token" class="nav-link active"> Génération de token </a><br><br>
             <a routerLink="/viewapi" class="nav-link"> Historique </a><br><br>
             <a class="nav-link"
               onclick="window.open('/apimenu/pzz/joffres/xy', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');">
             API

             </a><br><br>
               <a routerLink="/paiement" class="nav-link">Environnement de paiement</a><br><br>
               <a routerLink="/payementSortant" class="nav-link">Paiements effectués</a><br><br>

               <a routerLink="/viewclient" class="nav-link"> Liste des clients</a>
           </section>
          </nav>
        </div>
      </clr-main-container>
