import { Component, OnInit ,ElementRef,ViewChild} from '@angular/core';
import{ApiService} from '../api.service';
import axios from "axios";
declare var NI;


@Component({
  selector: 'app-visa',
  templateUrl: './visa.component.html',
  styleUrls: ['./visa.component.css']
})
export class VisaComponent implements OnInit {

  @ViewChild('nvisa', { static: true }) paypalElement: ElementRef;


  constructor(private _apiService:ApiService) { }

  ngOnInit(): void {
    //this.init_payment()
     this.access_token()
  }
  access_token(){
     this._apiService.access_token().subscribe(
        (resp:any) => {
            //this.paymentToken=resp.access_token
            console.log(resp);
        },
        (resp) => {
            console.log("resp-error");
            console.log(resp);
        }
        );

   }
   init_payment(){

    window.NI.mountCardInput('#nvisa'/* the mount id*/, {
      style:{
        color:'red'
      }, // Style configuration you can pass to customize the UI
      hostedSessionApiKey:"MTE5N2EwZTAtMGRjYy00NzA0LWI4YzYtYWZkNDBmNzYxZmI3OmRhNDU5NTIwLTc1M2MtNGE2MS1iN2Q5LTJjMWY5OGFkYTMyMg==", // // Hosted Session Key which is used to generate Session ID
      outletRef:"4e86e07d-e8af-4a4d-a50d-7bf4fa215aed", // outlet reference from the portal
      onSuccess:"www.jpay.com", // Success callback if hostedSessionApiKey validation succeeds
      onFail:"www.jofedigital.com", // Fail callback if hostedSessionApiKey validation fails
      apiKey:"MTE5N2EwZTAtMGRjYy00NzA0LWI4YzYtYWZkNDBmNzYxZmI3OmRhNDU5NTIwLTc1M2MtNGE2MS1iN2Q5LTJjMWY5OGFkYTMyMg==",
      onChangeValidStatus: ({
            isCVVValid,
            isExpiryValid,
            isNameValid,
            isPanValid
        }) => {
            console.log(isCVVValid, isExpiryValid, isNameValid, isPanValid);
        }
    });
   }
}
