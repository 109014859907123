


<clr-main-container>
  <clr-header>

    <div class="header-nav" [clr-nav-level]="1">
      <div class="branding text-center">JPAY Administration</div>
    </div>

  </clr-header>

  <div class="content-container">
    <main class="content-area" >
      <div class="n">


      <br><br>
      <h4>Environnement de paiement </h4><br>
      <div class="paiement">
        <form [formGroup]="addForm" novalidate class="form">
      <table class="tb">
      <tr>
        <td>  <div class="input-group form-group">
           Selectionner un site <select class="sel" name="site" formControlName="site" class="form-control">
             <option></option>
                            <option *ngFor="let ct of copyTraces"
                                [value]="ct.site"
                              >
                              {{ct.site}}</option>
           </select>
        </div></td>
        <td></td><td><div class="bt">
          <button  name="button" class="btn btn-primary" (click)="Rechercher()">
            Valider</button>
        </div></td>
      </tr>
      </table>
      <br>
      <div  *ngIf="tot" class="row">
      <table>
        <tr>
        Total  <td class="total">  <div  class="total">
               <input type="text" name="" [(ngModel)]=" val2" [ngModelOptions]="{standalone: true}" >
            </div></td><td></td>  TTC<td class="total">  <div  class="total">
               <input type="text" name="" [(ngModel)]="val3" [ngModelOptions]="{standalone: true}" >
              </div></td><td>  <button type="button" name="button" class="btn btn-success" (click)="payer()">Payer</button><br></td>
        </tr>
      </table>

      <br>




      </div>


      <circle-progress *ngIf="loader"
      [percent]="100"
      [radius]="100"
      [outerStrokeWidth]="16"
      [innerStrokeWidth]="4"
      ></circle-progress>



        </form>
      </div>
      <br>
      <!--<div *ngIf="affiche" class="result">
      <table id="dtBasicExample2" class="table table-striped table-bordered table-sm" cellspacing="0" width="100%">
        <thead>
          <tr class="rt">
            <th class="th-sm">Numero

            </th>
            <th class="th-sm">Montant

            </th>
            <th class="th-sm">Taxe

            </th>
            <th class="th-sm">TTC

            </th>
            <th class="th-sm">Id_commande

            </th>
            <th class="th-sm">Api

            </th>
            <th class="th-sm">Date

            </th>

          </tr>
        </thead>

      <tbody>
        <tr class="ligne" *ngFor="let cts of copycts">
          <td class=" text-center">{{cts.numero}}</td>
          <td class=" text-center">{{cts.montant}}</td>
          <td class=" text-center">{{cts.montant*0.03}}</td>
          <td class=" text-center">{{cts.montant-(cts.montant*0.03)}}</td>
          <td class=" text-center">{{cts.id_commande}}</td>
            <td class=" text-center">{{cts.api}}</td>
          <td class=" text-center">{{cts.created_at | date:'dd/MM/yyyy à H:mm'}}</td>




      </tr>
      </tbody>
      </table>

      </div>-->
      </div>

      <br>




      <div class="" *ngIf="affiche">


            <div class="content-container">


                <clr-datagrid>
                  <clr-dg-column> Numero</clr-dg-column>
                    <clr-dg-column>Id_commande</clr-dg-column>
                    <clr-dg-column>Montant</clr-dg-column>
                    <clr-dg-column>CommissionOperateur</clr-dg-column>
                    <clr-dg-column>CommissionJofe</clr-dg-column>
                    <clr-dg-column>TTC</clr-dg-column>
                    <clr-dg-column>Api</clr-dg-column>
                    <clr-dg-column>Date</clr-dg-column>
                      <clr-dg-column>Heure</clr-dg-column>
                  <clr-dg-row *clrDgItems="let cts of copyTrace">
                    <!-- debut du code des actions-->

                    <!-- fin du code des actions-->
                    <clr-dg-cell>{{cts.numero}}</clr-dg-cell>
                    <clr-dg-cell>{{cts.id_commande}}</clr-dg-cell>
                    <clr-dg-cell>{{cts.montant}}</clr-dg-cell>
                    <clr-dg-cell >{{(cts.montant*(cts.tauxOperateur/100)).toFixed(2)}}</clr-dg-cell>
                    <clr-dg-cell >{{(cts.montant*(cts.tauxJofe/100)).toFixed(2)}}</clr-dg-cell>
                    <clr-dg-cell>{{(cts.montant-(cts.montant*(cts.tauxOperateur/100)+cts.montant*(cts.tauxJofe/100))).toFixed(2)}}</clr-dg-cell>
                    <clr-dg-cell>{{cts.api}}</clr-dg-cell>
                    <clr-dg-cell>{{cts.created_at | date:'dd/MM/yyyy'}} </clr-dg-cell>
                    <clr-dg-cell>{{cts.created_at | date:'H:mm'}} </clr-dg-cell>
                  </clr-dg-row>

                  <clr-dg-footer>
                    <clr-dg-pagination #pagination [clrDgPageSize]="10">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Historiques par page</clr-dg-page-size>
                      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} des {{pagination.totalItems}} historiques
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>

            </div>
            <br>






      </div>
    </main>
    <nav class="sidenav" [clr-nav-level]="2">
     <section class="sidenav-content">
       <a routerLink="/viewapi" class="nav-link"> Historique </a><br><br>
       <a class="nav-link"
         onclick="window.open('/apimenu/pzz/joffres/xy', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');">
       API

       </a><br><br>
         <a routerLink="/paiement" class="nav-link active">Environnement de paiement</a><br><br>
         <a routerLink="/payementSortant" class="nav-link">Paiements effectués</a><br><br>

         <a routerLink="/viewclient" class="nav-link"> Liste des clients</a>
     </section>
    </nav>
  </div>
</clr-main-container>
