


                  <clr-main-container>
                    <clr-header>

                      <div class="header-nav" [clr-nav-level]="1">
                        <div class="branding text-center">JPAY Administration</div>
                      </div>

                    </clr-header>

                    <div class="content-container">
                      <main class="content-area" >

                              <h3>Modification du mot de passe d'un client</h3><br>
                              <body>
                              <div id="login">
                                  <div class="container">
                                      <div id="login-row" class="row justify-content-center align-items-center">
                                          <div id="login-column" class="col-md-6">
                                              <div id="login-box" class="col-md-12">
                                                  <form  [formGroup]="addForm" id="login-form" class="form" >
                                                      <h3 class="text-center text-info">Connexion</h3>

                                                      <div class="form-group">
                                                          <label for="password" class="text-info">Mot de passe:</label><br>
                                                          <input [type]="show ? 'text' : 'password'" name="password" class="form-control" formControlName="password"  placeholder="password"/>
                                              						<button (click)="password()"  class="fa fa-eye-slash" ></button>
                                                      </div>
                                                      <div class="form-group">
                                                          <label for="password2" class="text-info">Confirmer le mot de passe:</label><br>
                                                          <input type="password2" name="password2" id="password2" class="form-control" formControlName="password2">
                                                      </div>

                                                      <div class="form-group">
                                                          <button type="submit"
                                                                  class="btn btn-primary btn-block"
                                                                  [disabled]="addForm.invalid"
                                                                  (click)="onSubmit()"

                                                          > Valider  </button>
                                                      </div>
                                                  </form>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              </body>
                      </main>
                      <nav class="sidenav" [clr-nav-level]="2">
                       <section class="sidenav-content">
                         <a class="nav-link"
                           onclick="window.open('/apimenu/pzz/joffres/xy', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');">
                         API

                         </a><br><br>
                           <a routerLink="/paiement" class="nav-link">Environnement de paiement</a><br><br>
                           <a routerLink="/payementSortant" class="nav-link">Paiements effectués</a><br><br>

                           <a routerLink="/viewclient" class="nav-link"> Liste des clients</a>
                       </section>
                      </nav>
                    </div>
                  </clr-main-container>
