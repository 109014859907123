import { Component, OnInit } from '@angular/core';
import{ApiService} from '../api.service';
import { Router,Params,ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-rebour',
  templateUrl: './rebour.component.html',
  styleUrls: ['./rebour.component.css']
})

export class RebourComponent implements OnInit {
  time: number = 120;
  request_id:string;
  errorM:string
  rpsStatus:any;
  lien:string;
  selected:boolean=false;
  test:boolean=false
  interval:any;
  constructor(private _apiService:ApiService,
              private router:Router,private routes:ActivatedRoute) {}


  ngOnInit()
  {
    this.interval=setInterval(() => {
       if (this.time > 0) {this.time--; }
       else {this.test=true

       this.checkstatus()
       }


     }, 1000);

    const routeParams=this.routes.snapshot.params
    this.request_id =routeParams.request_id
    this.lien=routeParams.lien

  }
  checkstatus(){
    clearInterval(this.interval);
    this._apiService.Check_transaction_status(this.request_id).subscribe((res:Response)=>{
    this.rpsStatus=res
    console.log(this.rpsStatus.message)
    if(this.rpsStatus.message.indexOf("SUCCESS")!==-1){
      this.errorM="Paiement effectué avec succès"
      setTimeout(()=>{
     this.errorM=null;
   },2000)
      window.location.href=this.lien
      }

      else{
        this.errorM="Paiement echoué, transaction non confirmée"
        setTimeout(()=>{
       this.errorM=null;
     },5000)
        //alert(this.rpsStatus.message)
        window.location.href=this.lien
        }

    })
    //console.log(this.request_id)
  }
}
