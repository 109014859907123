import { Component, OnInit } from '@angular/core';
import {Payementsortant} from "../payementsortant";
import {Client} from "../client";
import {Tracepayee} from "../tracepayee";
import { Router } from "@angular/router";
import{FormBuilder,FormGroup,Validators} from '@angular/forms';
import{ApiService} from '../api.service';
@Component({
  selector: 'app-payementsortant',
  templateUrl: './payementsortant.component.html',
  styleUrls: ['./payementsortant.component.css']
})
export class PayementsortantComponent implements OnInit {
loader:boolean
  affiche :boolean=false
  affiches :boolean=false
  tot :boolean=false
  copyTrace:Payementsortant[]
  copyTraces:Client[]
tracePayee:Tracepayee[]
  addForm:FormGroup;
  val:number=0
  val2:number
    constructor(private router:Router,private _apiService:ApiService,private formBuilder:FormBuilder) { }

    ngOnInit(): void {
      this.loadPayementSortant()
      //this.loadTracePayee()
      this.addForm=this.formBuilder.group({

   site:['',[Validators.required]],

    });

    }
  Rechercher(){
    this.loader=true
    this._apiService.getPayementSortantBySite(this.addForm.value.site).subscribe((data:Payementsortant[])=>
    {
      this.copyTrace=data
      console.log(this.copyTrace)
     this.loader=false
      if (this.copyTrace.length===0) {
      this.affiches=true;
        this.tot=true;
        this.val2=0
      }
      else{
        for (let index = 0; index < this.copyTrace.length; index++) {
        this.val = this.val+Number(this.copyTrace[index].montant);
        this.val2=this.val


      }
      //this.val=this.copyTrace[0].total
    //  this.affiche=false;
      this.affiches=true;
      //this.tot=false;
      //this.val=0;
    this.loadTracePayee()
    //console.log(this.copyTrace)
  }

    },
    error => {console.log('erreur')},
    () => {console.log('traces chargées avec succès')}

    );
  }


  loadPayementSortant(){
  this._apiService.getCopytrace().subscribe((data:Client[])=>
  {
    this.copyTraces=data

  },
  error => {console.log('erreur')},
  () => {console.log('traces chargées avec succès')}

  );
  }

  loadTracePayee(){
  this._apiService.getTracePayeeBySite(this.addForm.value.site).subscribe((data:Tracepayee[])=>
  {
    this.tracePayee=data



  },
  error => {console.log('erreur')},
  () => {console.log('traces chargées avec succès')}

  );
  }

  back(){
    this.router.navigateByUrl('/viewapi')
  }


}
