import { QueryList,Input ,ViewChildren,Component, OnInit, ElementRef, HostListener, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
//import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import{ApiService} from '../api.service';
import{Api} from '../api';
import{Trace} from '../traces';
import { Router } from "@angular/router";
import {DecimalPipe} from '@angular/common';

import {Observable} from 'rxjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap' ;

import {SortableDirective, SortEvent} from '../sortable.directive';



import{TraceserviceService} from '../traceservice.service';
import {
    EditSettingsModel,
    CommandModel,
    GridComponent
} from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-viewapi',
  templateUrl: './viewapi.component.html',
  styleUrls: ['./viewapi.component.css']
})
export class ViewapiComponent implements OnInit {
  @ViewChild('grid', {static: false})
      public grid: GridComponent;

public formatoptions =  {type:'date', format:'dd/MM/yyyy'}
  readonly editSetting: EditSettingsModel = {
        allowAdding: false,
        allowDeleting: true,
        allowEditing: true,
        allowEditOnDblClick: false,
        showConfirmDialog: true,
        showDeleteConfirmDialog: true
    };

      public filterSettings: any;
  traces$: Observable<Trace[]>;
  total$: Observable<number>;
  //trace:Trace[];
  api:Api[];
    x:Api[];
  _id:number;
  traces:Trace[];
  @Input() affichage:boolean=false
  _id2:number;
  jofe:any="../assets/images/jof.JPG"

    @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;
  constructor(private _apiService:ApiService,
            private router:Router,public _traceService:TraceserviceService) {
              //this.traces$ = _traceService.trace_s;
              //this.total$ = _traceService.total$;
             }

  ngOnInit(){
    this.filterSettings = {type: 'Menu'};
//var x="44gffgngjkfg43222".charCodeAt()
//console.log(x)
  this.loadApi();
  this.loadTrace();

  //this.intApi();

  }
/*testwindow(){

    var win=window.open('/apimenu/100/jpay/2','menubar=0','toolbar=no,width=550,height=500') ;
    win.addEventListener('DOMContentLoaded', function () {
      win.history.replaceState(null, null, 'url');   // must be same domain (or ignore domain)
  })
}*/

testwindow(){
  this._apiService.testRedirection().subscribe((data:any)=>{
    console.log(data)
  })
/*var win = window.open ('https://presence-frontend.herokuapp.com', '_blank', 'location = no, width = 640, height = 360');
    win.addEventListener('DOMContentLoaded', function () {
      win.history.replaceState(null, null, 'test');   // must be same domain (or ignore domain)
  })*/
}

/*
callJpay(){

    var win=window.open('https://www.jpayafrica.jofedigital.com/apimenu/montant/nomDuSite/idCommande','menubar=0','toolbar=no') ;
    win.addEventListener('DOMContentLoaded', function () {
      win.history.replaceState(null, null, 'www.jofedigital.com');
  })
}



*/
  /*onSort({column, direction}: SortEvent) {
      // resetting other headers
      this.headers.forEach(header => {
        if (header.sortable !== column) {
          header.direction = '';
        }
      });

      this._traceService.sortColumn = column;
      this._traceService.sortDirection = direction;
    }*/
  loadTrace(){
  this._apiService.getTrace().subscribe((data:Trace[])=>
  {this.traces=data
    console.log(this.traces)
  for (let index = 0; index < this.traces.length; index++) {
    this.traces[index]['montant2']=+this.traces[index]['montant']
    if (this.traces[index].api.toString()=="Orange Money") {
      this.traces[index]['tauxOperateur']=this.traces[index].tauxOrange
      this.traces[index]['CommissionOperateur']=this.traces[index].tauxOrange * this.traces[index]['montant2']/100
      this.traces[index]["CommissionJofe"]=this.traces[index].tauxJofe * this.traces[index]['montant2']/100
      this.traces[index]["TTC"]=this.traces[index]['montant2']-(this.traces[index]['CommissionOperateur']+this.traces[index]['CommissionJofe'])
    }
    else if (this.traces[index].api.toString()=="MoovMoney") {
        this.traces[index]['tauxOperateur']=this.traces[index].tauxMoov
        this.traces[index]['CommissionOperateur']=this.traces[index].tauxMoov * this.traces[index]['montant2']/100
        this.traces[index]["CommissionJofe"]=this.traces[index].tauxJofe * this.traces[index]['montant2']/100
        this.traces[index]["TTC"]=this.traces[index]['montant2']-(this.traces[index]['CommissionOperateur']+this.traces[index]['CommissionJofe'])


      }
    else if (this.traces[index].api.toString()=="Carte de crédit") {
          this.traces[index]['tauxOperateur']=this.traces[index].tauxVisa
          this.traces[index]['CommissionOperateur']=this.traces[index].tauxVisa * this.traces[index]['montant2']/100
          this.traces[index]["CommissionJofe"]=this.traces[index].tauxJofe * this.traces[index]['montant2']/100
          this.traces[index]["TTC"]=this.traces[index]['montant2']-(this.traces[index]['CommissionOperateur']+this.traces[index]['CommissionJofe'])

      }


      var date=new Date(this.traces[index]["created_at"])
      var x=date.toUTCString()
      let array=x.split(" ")
      var date1=array[1]+"/"+array[2]+"/"+array[3]
      var h=array[4]
      this.traces[index]["date"]=date1
      this.traces[index]["heure"]=h


  }



  /*for (let index = 0; index < this.traces.length; index++) {
    this.traces[index]["CommissionOperateur"]=(this.traces[index]['montant2'])*(this.traces[index].tauxOperateur/100).toFixed(2)
    this.traces[index]["CommissionJofe"]=(this.traces[index]['montant2'])*(this.traces[index]['tauxJofe']/100).toFixed(2)
    this.traces[index]["TTC"]=this.traces[index]['montant2']-(this.traces[index]['CommissionOperateur']+this.traces[index]['CommissionJofe'])

  }*/
  console.log(this.traces)

},
  error => {console.log('erreur')},
  () => {console.log('traces chargées avec succès')}

  );
  }
  loadApi(){
  this._apiService.getApi().subscribe((data:Api[])=>
  {this.api=data},
  error => {console.log('erreur')},
  () => {console.log('apis chargés avec succès')}

  );
  }

/*  intApi(){
    this._apiService.initApi().subscribe((data:Api[])=>
    {this.x=data},
    error => {console.log('erreur')},
    () => {console.log('apis chargés avec succès')}

    );
  } */
  delete(api:Api):void{
  this._apiService.deleteApi(api.id)
  .subscribe(data=>{
   this.api=this.api.filter(u => u !== api);
  });

  }

  edit(api:Api){
  this._id=api.id;
  this.router.navigateByUrl("/home/id/(homeOutlet:ediapi/"+this._id+")" );

  }



}
