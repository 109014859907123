



<clr-main-container>
  <clr-header>

    <div class="header-nav" [clr-nav-level]="1">
      <div class="branding text-center">JPAY Administration</div>
    </div>

  </clr-header>

  <div class="content-container">
    <main class="content-area" >


        <div class="n">


        <br><br>
        <h4>Paiements effectués </h4><br>
        <circle-progress *ngIf="loader"
        [percent]="100"
        [radius]="100"
        [outerStrokeWidth]="16"
        [innerStrokeWidth]="4"
        ></circle-progress>
        <div class="paiement">
          <form [formGroup]="addForm" novalidate class="form">
      <table class="tb">
        <tr>
          <td>  <div class="input-group form-group">
             Selectionner un site <select class="sel" name="site" formControlName="site" class="form-control">
               <option></option>
                              <option *ngFor="let ct of copyTraces"
                                  [value]="ct.site"
                                >
                                {{ct.site}}</option>
             </select>
          </div></td>
          <td></td><td><div class="bt">
            <button  name="button" class="btn btn-primary" (click)="Rechercher()">
              Valider</button>
          </div></td>
        </tr>
      </table>
      <br>
      <!--<div  *ngIf="tot" class="row">
        <div  class="total">
          Total: <input type="text" name="" [(ngModel)]="val2" [ngModelOptions]="{standalone: true}" >
        </div>

      <br><br>

      </div>-->






          </form>
        </div>
      <br>

      <div *ngIf="affiches && !loader" class="result">
        <clr-datagrid>
          <clr-dg-column> Montant total</clr-dg-column>
          <clr-dg-column>Site</clr-dg-column>

            <clr-dg-column>Date</clr-dg-column>
            <clr-dg-column>Heure</clr-dg-column>

          <clr-dg-row *clrDgItems="let cts of tracePayee">
            <!-- debut du code des actions-->

            <!-- fin du code des actions-->
            <clr-dg-cell>{{cts.total}}</clr-dg-cell>
            <clr-dg-cell>{{cts.site}}</clr-dg-cell>
            <clr-dg-cell>{{cts.created_at | date:'dd/MM/yyyy'}} </clr-dg-cell>
            <clr-dg-cell>{{cts.created_at | date:'H:mm'}} </clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
              <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Paiements par page</clr-dg-page-size>
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} des {{pagination.totalItems}} paiements
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
        <!--<table id="dtBasicExample2" class="table table-striped table-bordered table-sm" cellspacing="0" width="100%">
          <thead>
            <tr class="rt">
              <th class="th-sm">Montant total

              </th>
              <th class="th-sm">Site

              </th>
              <th class="th-sm">Date

              </th>


            </tr>
          </thead>

        <tbody>
          <tr class="ligne" *ngFor="let cts of tracePayee">
            <td class=" text-center">{{cts.total}}</td>
            <td class=" text-center">{{cts.site}}</td>
            <td class=" text-center">{{cts.created_at | date:'dd/MM/yyyy à H:mm'}}</td>




        </tr>
        </tbody>
      </table>-->

        </div>
      <br>

      </div>
    </main>
    <nav class="sidenav" [clr-nav-level]="2">
     <section class="sidenav-content">
        <a routerLink="/viewapi" class="nav-link"> Historique </a><br><br>
       <a class="nav-link"
         onclick="window.open('/apimenu/pzz/joffres/xy', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');">
       API

       </a><br><br>
         <a routerLink="/paiement" class="nav-link">Environnement de paiement</a><br><br>
         <a routerLink="/payementSortant" class="nav-link active">Paiements effectués</a><br><br>

         <a routerLink="/viewclient" class="nav-link"> Liste des clients</a>
     </section>
    </nav>
  </div>
</clr-main-container>
