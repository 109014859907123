import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import {ApiService  } from "./api.service";
import { LoginComponent } from './login/login.component';
@Injectable({
  providedIn: 'root'
})
export class SecurisationGuard implements CanActivate {
  constructor(private userService:ApiService, private router:Router){

}
  canActivate(): Observable<boolean>| Promise<boolean> | boolean {
    return new Promise(
      (resolve,reject)=>{
        const isAth=this.userService.isAth;
          //console.log(isAth)
        if(isAth){
          resolve(true)
        }else{
          this.router.navigate(['/admin']);
          reject(false)
        }
      }
    );
  }


}
