import { Component, OnInit } from '@angular/core';
import {Client} from "../client";
import{FormBuilder,FormGroup,Validators} from '@angular/forms';
import{ApiService} from '../api.service';
import { Router } from "@angular/router";
import Swall from "sweetalert2"


@Component({
  selector: 'app-viewclient',
  templateUrl: './viewclient.component.html',
  styleUrls: ['./viewclient.component.css']
})
export class ViewclientComponent implements OnInit {

clients:Client[]
_id:number
  constructor(private router:Router,private _apiService:ApiService,private formBuilder:FormBuilder) { }

  ngOnInit(): void {
      this.loadCopyTrace()
  }

  loadCopyTrace(){
  this._apiService.getCopytrace().subscribe((data:Client[])=>
  {
    this.clients=data

  },
  error => {console.log('erreur')},
  () => {console.log('clients chargés avec succès')}

  );
  }

  delete(client:Client){
    if(confirm("Voulez-vous vraiment suprimer ce client")) {
      this._apiService.deleteClient(client.id)
       .subscribe(data=>{
         this.clients=this.clients.filter(u => u !== client);
       });
     this.router.navigateByUrl("viewclient");

 }else{
   this.router.navigateByUrl("viewclient");
 }


  }
  ressetPassword(client:Client){
    this._id=client.id;
    this.router.navigateByUrl("/editclientPassword/"+this._id);
  }


  accessToken(client:Client){
      this._apiService.access(client.id).subscribe((data:any)=>{
        Swall.fire("Token client ",data.access_token,'success')

      })
  }
  edit(client:Client){
    this._id=client.id;
    this.router.navigateByUrl("/editclient/"+this._id);

  }
  back(){
    this.router.navigateByUrl('/viewapi')
  }

}
