







    <!--
            <div class="back">
                <button type="button" name="button" class="btn btn-danger" (click)="back()">Retour</button>
            </div>
                <h3>Ajout d'un nouveau client</h3><br>
                <div class="container text-center">
                  <form [formGroup]="addForm" novalidate class="form" >

                    <div class="alert alert-danger text-center"
                  *ngIf="addForm.get('nom').hasError('required') && addForm.get('nom').touched">
                  nom  obligatoire
                  </div>
                  <div class="form-group">
                    <label for="exampleFormControlInput1">Nom</label>
                    <input type="text"
                    class="form-control"
                    size="{{taille}}"
                     placeholder="Entrez le nom"
                     formControlName="nom"
                      name="nom"
                    >
                  </div>


                  <div class="alert alert-danger text-center"
                  *ngIf="addForm.get('prenom').hasError('required') && addForm.get('prenom').touched">
                  prénom  obligatoire
                  </div>
                  <div class="form-group">
                  <label for="exampleFormControlInput1">Prénom</label>
                  <input type="text"
                  class="form-control"

                   placeholder="Entrez le prenom"
                   formControlName="prenom"
                    name="prenom"
                  >
                  </div>

                  <div class="alert alert-danger text-center"
                  *ngIf="addForm.get('mail').hasError('required') && addForm.get('mail').touched">
                  addresse mail  obligatoire
                  </div>
                  <div class="alert alert-danger text-center" *ngIf="addForm.get('mail').hasError('pattern')">
                       adresse mail invalide ! </div>
                  <div class="form-group">
                  <label for="exampleFormControlInput1">Adresse mail</label>
                  <input type="email"
                  class="form-control"

                   placeholder="Entrez l'addresse mail"
                   formControlName="mail"
                    name="mail"
                    pattern="^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  >
                  </div>

                  <div class="alert alert-danger text-center"
                  *ngIf="addForm.get('password').hasError('required') && addForm.get('password').touched">
                  mot de passe obligatoire
                  </div>
                  <div class="form-group">
                  <label for="exampleFormControlInput1">Mot de passe</label>
                  <input type="password"
                  class="form-control"

                   placeholder="Entrez le mot de passe"
                   formControlName="password"
                    name="password"
                  >
                  </div>
                  <div class="alert alert-danger text-center"
                  *ngIf="addForm.get('password2').hasError('required') && addForm.get('password2').touched">
                  mots de passe diifferents
                  </div>
                  <div class="alert alert-danger text-center"
                  *ngIf="addForm.get('password2').hasError('required') && addForm.get('password2').touched">
                  confirmation  obligatoire
                  </div>
                  <div class="" *ngIf=" (addForm.get('password2').value!== addForm.get('password').value) && addForm.get('password2').touched ">
                    Vos mots de passe sont diifferents
                  </div>
                  <div class="form-group">
                  <label for="exampleFormControlInput1">Confirmer le mot de passe</label>
                  <input type="password"
                  class="form-control"

                   placeholder="Entrez le nom du site"
                   formControlName="password2"
                    name="password2"
                  >
                  </div>
                  <div class="alert alert-danger text-center"
                  *ngIf="addForm.get('site').hasError('required') && addForm.get('site').touched">
                  site  obligatoire
                  </div>
                  <div class="form-group">
                  <label for="exampleFormControlInput1">Nom du site web</label>
                  <input type="text"
                  class="form-control"

                   placeholder="Entrez le nom du site"
                   formControlName="site"
                    name="site"
                  >
                  </div>

                                      <div class="alert alert-danger text-center"
                               *ngIf="addForm.get('numero').hasError('required') && addForm.get('numero').touched">
                                 numero  obligatoire
                               </div>
                               <div class="alert alert-danger" *ngIf="addForm.get('numero').hasError('pattern')">
                            Veuillez respecter le format :11111111 </div>
                                      <div class="form-group">
                                        <label for="exampleFormControlInput1">Numero</label>
                                        <input type="text"
                                        class="form-control"
                                         id="exampleFormControlInput1"
                                         placeholder="Entrez le numero"
                                         formControlName="numero"
                                          name="numero"

                                          pattern="^\d{2}\d{2}\d{2}\d{2}$">
                                      </div>
                                      <div class="alert alert-danger text-center"
                                      *ngIf="addForm.get('lien').hasError('required') && addForm.get('lien').touched">
                                      Domaine  obligatoire
                                      </div>
                                      <div class="form-group">
                                      <label for="exampleFormControlInput1">Domaine du site</label>
                                      <input size="20" type="text"
                                      class="form-control"

                                       placeholder="Entrez le domaine du site"
                                       formControlName="lien"
                                        name="lien"
                                      >
                                      </div>




                  <ul role="tablist"
                      class="nav  nav-fill mb-6">
                      <li class="nav-item">  <div class="form-group">
                             <button type="button" [disabled]="addForm.invalid"
                                          class="btn btn-success "
                                          (click)="onSubmit()">
                                          <i class="fas fa-mobile-alt mr-2">
                                        Valider</i>
                                   </button>
                          </div></li>
                        <li class="nav-item"><div class="form-group">
                             <button type="button"
                                     class="btn btn-danger "
                                       (click)="resetF()">

                          <i class="fas fa-mobile-alt mr-2">
                                          Effacer la saisie </i>
                                   </button>
                          </div></li>
                        </ul>



                                </form>

                                <div class="form-group input-group">
                              		<div class="input-group-prepend">
                              		    <span class="input-group-text"> <i class="fa fa-user"></i> </span>
                              		 </div>
                                      <input name="" class="form-control" placeholder="Full name" type="text">
                                  </div>
                </div>

    -->



    <clr-main-container>
      <clr-header>

        <div class="header-nav" [clr-nav-level]="1">
          <div class="branding text-center">JPAY Administration</div>
        </div>

      </clr-header>

      <div class="content-container">
        <main class="content-area" >

          <article class="card-body mx-auto" style="max-width: 400px;">

            <h3>Ajout d'un nouveau client</h3><br>
            <div class="alert alert-success" *ngIf="errorM">
              {{errorM}}
            </div>
            <form [formGroup]="addForm">
              <div class="alert alert-danger" *ngIf="addForm.get('numero').hasError('pattern')">
           Veuillez respecter le format :11111111 </div>
           <div class="" *ngIf=" (addForm.get('password2').value!== addForm.get('password').value) && addForm.get('password2').touched ">
             Vos mots de passe sont diifferents
           </div>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-user"></i> </span>
               </div>
                  <input name="nom" formControlName="nom" class="form-control" placeholder="Nom" type="text">
              </div>
              <div class="form-group input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text"> <i class="fa fa-user"></i> </span>
                 </div>
                    <input name="prenom" formControlName="prenom" class="form-control" placeholder="Prenom" type="text">
                </div>

                   <!-- form-group// -->
              <div class="form-group input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-envelope"></i> </span>
               </div>
                  <input name="mail" formControlName="mail" class="form-control" placeholder="Adresse mail" type="email">
              </div> <!-- form-group// -->

              <div class="form-group input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-phone"></i> </span>
              </div>

                <input name="numero" pattern="^\d{2}\d{2}\d{2}\d{2}$" formControlName="numero" class="form-control" placeholder="Numero" type="text">
              </div> <!-- form-group// -->
            <!-- form-group end.// -->
              <div class="form-group input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
              </div>
                  <input name="password" formControlName="password" class="form-control" placeholder="Mot de passe" type="password">
              </div> <!-- form-group// -->
              <div class="form-group input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
              </div>
                  <input name="password2" formControlName="password2" class="form-control" placeholder="Mot de passe" type="password">
              </div> <!-- form-group// -->
              <div class="form-group input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text"> <i class="fa fa-web"></i> </span>
                 </div>
                    <input name="site" formControlName="site" class="form-control" placeholder="Nom du site" type="text">
                </div>
                <div class="form-group input-group">
                  <div class="input-group-prepend">
                      <span class="input-group-text"> <i class="fa fa-web"></i> </span>
                   </div>
                      <input name="lien" formControlName="lien" class="form-control" placeholder="Domaine" type="text">
                  </div>
                  <div class="form-group input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"> <i class="fa fa-web"></i> </span>
                     </div>
                        <input name="taux" formControlName="taux" class="form-control" placeholder="taux" type="text">
                    </div>
                    <div class="form-group input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text"> <i class="fa fa-web"></i> </span>
                       </div>
                          <input name="tauxMoov" formControlName="tauxMoov" class="form-control" placeholder="tauxMoov" type="text">
                      </div>
                      <div class="form-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <i class="fa fa-web"></i> </span>
                         </div>
                            <input name="tauxOrange" formControlName="tauxOrange" class="form-control" placeholder="tauxOrange" type="text">
                        </div>
                        <div class="form-group input-group">
                          <div class="input-group-prepend">
                              <span class="input-group-text"> <i class="fa fa-web"></i> </span>
                           </div>
                              <input name="tauxJofe" formControlName="tauxJofe" class="form-control" placeholder="tauxJofe" type="text">
                          </div>
                          <div class="form-group input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"> <i class="fa fa-web"></i> </span>
                             </div>
                                <input name="tauxVisa" formControlName="tauxVisa" class="form-control" placeholder="tauxVisa" type="text">
                            </div>
              <div class="form-group">
                  <button type="submit"
                          class="btn btn-primary btn-block"
                          [disabled]="addForm.invalid"
                          (click)="onSubmit()"

                  > Soumettre  </button>
              </div> <!-- form-group// -->

          </form>
          </article>
        </main>
        <nav class="sidenav" [clr-nav-level]="2">
         <section class="sidenav-content">
           <a routerLink="/viewapi" class="nav-link"> Historique </a><br><br>
           <a class="nav-link"
             onclick="window.open('/apimenu/pzz/joffres/xy', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');">
           API

           </a><br><br>
             <a routerLink="/paiement" class="nav-link">Environnement de paiement</a><br><br>
             <a routerLink="/payementSortant" class="nav-link">Paiements effectués</a><br><br>

             <a routerLink="/viewclient" class="nav-link"> Liste des clients</a>
         </section>
        </nav>
      </div>
    </clr-main-container>


   <!-- card.// -->


    <!--container end.//-->
