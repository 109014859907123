<div class="container rounded bg-white mt-5 mb-5">

    <div class="row">
        <div class="col-md-3 border-right">
            <div class="d-flex flex-column align-items-center text-center p-3 py-5"><img class="rounded-circle mt-5" width="150px" src="assets/images/unnamed.png"><span class="font-weight-bold">{{nom}} {{prenom}}</span><span class="text-black-50">{{email}}</span><span> </span></div>
        </div>
        <div class="col-md-5 border-right">
            <div class="p-3 py-5">
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <h4 class="text-right">Informations personnelles </h4>
                </div>
                <div class="row mt-2">
                    <div class="col-md-6"><label  class="labels">Nom : </label><input type="text" class="form-control" placeholder="nom" value="" [(ngModel)]="nom" disabled></div>
                    <div class="col-md-6"><label class="labels">Prénom</label><input type="text" class="form-control" value="" placeholder="prenom" [(ngModel)]="prenom" disabled></div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12"><label class="labels">Telephone </label><input type="text" class="form-control" placeholder="numero" value="" [(ngModel)]="telephone" disabled></div>
                    <div class="col-md-12"><label class="labels">Email</label><input type="text" class="form-control" placeholder="mail" value="" [(ngModel)]="email" disabled></div>
                </div>
                <!--<div class="row mt-3">
                    <div class="col-md-6"><label class="labels">Country</label><input type="text" class="form-control" placeholder="country" value=""></div>
                    <div class="col-md-6"><label class="labels">State/Region</label><input type="text" class="form-control" value="" placeholder="state"></div>
                </div>-->
                <div class="mt-5 text-center"><button class="btn btn-primary profile-button"  (click)="update()" type="button">Modifier mot de passe</button></div>
            </div>
        </div>

    </div>
</div>
