


                  <clr-main-container>
                    <clr-header>

                      <div class="header-nav" [clr-nav-level]="1">
                        <div class="branding text-center">JPAY Administration</div>
                      </div>

                    </clr-header>

                    <div class="content-container">
                      <main class="content-area" >

                              <h3>Modification d'un client</h3><br>
                            <form [formGroup]="addForm" novalidate class="form">

                              <div class="alert alert-danger"
                            *ngIf="addForm.get('nom').hasError('required') && addForm.get('nom').touched">
                            nom  obligatoire
                            </div>
                            <div class="form-group">
                              <label for="exampleFormControlInput1">Nom</label>
                              <input type="text"
                              class="form-control"

                               placeholder="Entrez le nom"
                               formControlName="nom"
                                name="nom"
                              >
                            </div>


                            <div class="alert alert-danger"
                            *ngIf="addForm.get('prenom').hasError('required') && addForm.get('prenom').touched">
                            prénom  obligatoire
                            </div>
                            <div class="form-group">
                            <label for="exampleFormControlInput1">Prénom</label>
                            <input type="text"
                            class="form-control"

                             placeholder="Entrez le prenom"
                             formControlName="prenom"
                              name="prenom"
                            >
                            </div>

                            <div class="alert alert-danger"
                            *ngIf="addForm.get('email').hasError('required') && addForm.get('email').touched">
                            addresse mail  obligatoire
                            </div>
                            <div class="alert alert-danger" *ngIf="addForm.get('email').hasError('pattern')">
                                 adresse mail invalide ! </div>
                            <div class="form-group">
                            <label for="exampleFormControlInput1">Adresse mail</label>
                            <input type="email"
                            class="form-control"

                             placeholder="Entrez l'addresse mail"
                             formControlName="email"
                              name="email"
                              pattern="^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            >
                            </div>


                            <div class="alert alert-danger"
                            *ngIf="addForm.get('site').hasError('required') && addForm.get('site').touched">
                            site  obligatoire
                            </div>
                            <div class="form-group">
                            <label for="exampleFormControlInput1">Nom du site web</label>
                            <input type="text"
                            class="form-control"

                             placeholder="Entrez le nom du site"
                             formControlName="site"
                              name="site"
                            >
                            </div>
                            <div class="alert alert-danger"
                            *ngIf="addForm.get('lien').hasError('required') && addForm.get('lien').touched">
                            Domaine  obligatoire
                            </div>
                            <div class="form-group">
                            <label for="exampleFormControlInput1">Domaine du site</label>
                            <input type="text"
                            class="form-control"

                             placeholder="Entrez le domaine"
                             formControlName="lien"
                              name="lien"
                            >
                            </div>


                                                <div class="alert alert-danger"
                                         *ngIf="addForm.get('telephone').hasError('required') && addForm.get('telephone').touched">
                                           numero  obligatoire
                                         </div>
                                         <div class="alert alert-danger" *ngIf="addForm.get('telephone').hasError('pattern')">
                                      Veuillez respecter le format :11111111 </div>
                                                <div class="form-group">
                                                  <label for="exampleFormControlInput1">Numero</label>
                                                  <input type="text"
                                                  class="form-control"
                                                   id="exampleFormControlInput1"
                                                   placeholder="Entrez le numero"
                                                   formControlName="telephone"
                                                    name="telephone"
                                                    pattern="^\d{2}\d{2}\d{2}\d{2}$">
                                                </div>
                                                <div class="form-group">
                                                  <label for="exampleFormControlInput">Taux</label>
                                                  <input type="text"
                                                  class="form-control"
                                                   id="exampleFormControlInput1"
                                                   placeholder="Taux"
                                                   formControlName="taux"
                                                    name="taux"
                                                  >
                                                </div>
                                                <div class="form-group">
                                                  <label for="exampleFormControlInput">TauxMoov</label>
                                                  <input type="text"
                                                  class="form-control"
                                                   id="exampleFormControlInput1"
                                                   placeholder="TauxMoov"
                                                   formControlName="tauxMoov"
                                                    name="tauxMoov"
                                                  >
                                                </div>
                                                <div class="form-group">
                                                  <label for="exampleFormControlInput">TauxOrange</label>
                                                  <input type="text"
                                                  class="form-control"
                                                   id="exampleFormControlInput1"
                                                   placeholder="TauxOrange"
                                                   formControlName="tauxOrange"
                                                    name="tauxOrange"
                                                  >
                                                </div>
                                                <div class="form-group">
                                                  <label for="exampleFormControlInput">TauxJofe</label>
                                                  <input type="text"
                                                  class="form-control"
                                                   id="exampleFormControlInput1"
                                                   placeholder="TauxJofe"
                                                   formControlName="tauxJofe"
                                                    name="tauxJofe"
                                                  >
                                                </div>
                                                <div class="form-group">
                                                  <label for="exampleFormControlInput">TauxVisa</label>
                                                  <input type="text"
                                                  class="form-control"
                                                   id="exampleFormControlInput1"
                                                   placeholder="TauxVisa"
                                                   formControlName="tauxVisa"
                                                    name="tauxVisa"
                                                  >
                                                </div>


                                              <!--<div class="form-group">
                                                <input type="hidden"
                                                 class="form-control"
                                                 formControlName="montant"
                                                  name="montant"
                                                  [(ngModel)]="total"
                                                >

                                              </div>-->


                            <ul role="tablist"
                                class="nav  nav-fill mb-6">
                                <li class="nav-item">  <div class="form-group">
                                       <button type="button"
                                                    class="btn btn-success "
                                                    (click)="update()">
                                                    <i class="fas fa-mobile-alt mr-2">
                                                  Valider</i>
                                             </button>
                                    </div></li>

                                  </ul>



                                          </form>
                      </main>
                      <nav class="sidenav" [clr-nav-level]="2">
                       <section class="sidenav-content">
                         <a class="nav-link"
                           onclick="window.open('/apimenu/pzz/joffres/xy', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');">
                         API

                         </a><br><br>
                           <a routerLink="/paiement" class="nav-link">Environnement de paiement</a><br><br>
                           <a routerLink="/payementSortant" class="nav-link">Paiements effectués</a><br><br>

                           <a routerLink="/viewclient" class="nav-link"> Liste des clients</a>
                       </section>
                      </nav>
                    </div>
                  </clr-main-container>
