
<img [src]="oneas"/>
<nav class="navbar navbar-expand-lg navbar-light bg-light py-4">
  <div class="container">
    <div class="d-flex">
      <!-- Button trigger modal -->
      <button type="button" class="btn btn-secondary px-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample">
        <i class="bi bi-text-left"></i>
      </button>
    </div>
    <a class="navbar-brand" href="#">Accueil</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto ps-5 mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" style="color:white;" aria-current="page" href="#">Historique</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" style="color:white;" aria-current="page" href="#">Abonnées</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" style="color:white;" aria-current="page" href="#">Factures</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active"  style="color:white;" aria-current="page" href="#">Reclamations</a>
        </li>



      </ul>

    </div>
  </div>
</nav>
<h1>Historiques des paiements</h1>
<div class="content-container">

<div style="height: calc(80% - 86px)"><br><br>
                <ejs-grid [dataSource]='onea' #grid [allowPaging]='true' [allowSorting]='true' [editSettings]="editSetting"
                          [filterSettings]='filterSettings' [allowFiltering]='true' [allowGrouping]="false"

                          height="100%">

                    <e-columns>

                    <e-column field='id' headerText='#' textAlign='left' width='150'></e-column>


                        <e-column field='numeroabonne' headerText='Numero Abonné' textAlign='left' width='170'></e-column>


                        <e-column field='montant' headerText='Montant' textAlign='left' width='120'></e-column>

                        <e-column field='numero' headerText='Telephone' textAlign='left' width='120'></e-column>


                        <e-column field='api' headerText='Moyen de paiement' textAlign='left' width='120'></e-column>
                        <e-column field="date"   headerText='Date' textAlign='left' width='150'></e-column>
                        <e-column field="heure"  headerText='Heure' textAlign='left' width='150'></e-column>






                    </e-columns>

                </ejs-grid>
            </div>
                </div>
