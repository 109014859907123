<div class="tab-pane " id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
<div class="row grid-view theme1">
   <div class="col-sm-6 col-md-4 mb-30" >
  <h4>Transaction intialisée , veuillez composer <i>*555*6*1# </i>
     sur votre telephone pour finaliser le
      paiement ou <a href="tel:*555*6*1%23">Cliquez ici</a> (si vous ètes sur telephone) puis verifier votre paiement
       avant la fin du compte à rebours</h4>

       <br><h1 class="rebour">

         {{(time > 0) ? time :"Termié" }}
       </h1>
<div class="" *ngIf="errorM">
{{errorM}}
</div>
       <div class="" *ngIf="!test">
         <button class="btns1 "
                  type="button"
                  name="button"
                  (click)="checkstatus()">

                 Verifier votre paiement</button>

       </div>
     </div>

</div>
<br>
</div>



           <!--<h4>Transaction intialisée , veuillez composer <i>*555*6*1# </i>
              sur votre telephone pour finaliser le
               paiement ou <a href="tel:*555*6*1%23">Cliquez ici</a> (si vous ètes sur telephone) puis verifier votre paiement
                avant la fin du compte à rebours</h4>
           <br><br><h1 class="rebour">

             {{(time > 0) ? time :"Termié" }}
           </h1>
<div class="" *ngIf="errorM">
  {{errorM}}
</div>
           <div class="" *ngIf="!test">
             <button class="btns1 "
                      type="button"
                      name="button"
                      (click)="checkstatus()">

                     Verifier votre paiement</button>
           </div>

-->
