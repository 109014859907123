

<clr-main-container>
  <clr-header>

    <div class="header-nav" [clr-nav-level]="1">
      <div class="branding text-center">JPAY Administration</div>
    </div>

  </clr-header>

  <div class="content-container">
    <main class="content-area" >

    </main>
    <nav class="sidenav" [clr-nav-level]="2">
     <section class="sidenav-content">
       <a class="nav-link"
         onclick="window.open('/apimenu/pzz/joffres/xy', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');">
       API

       </a><br><br>
         <a routerLink="/paiement" class="nav-link">Environnement de paiement</a><br><br>
         <a routerLink="/payementSortant" class="nav-link">Paiements effectués</a><br><br>
         <a routerLink="/clients" class="nav-link"> Nouveau client</a><br><br>
         <a routerLink="/viewclient" class="nav-link"> Liste des clients</a>
     </section>
    </nav>
  </div>
</clr-main-container>
