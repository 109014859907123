

<br><br><br>
<div class="container">

	<circle-progress *ngIf="loader"
	[percent]="300"
	[radius]="50"
	[outerStrokeWidth]="16"
	[innerStrokeWidth]="4"
	></circle-progress>
  <img [src]="onea" class="center"/>
	<div class="d-flex justify-content-center h-100">
		<div class="card">

		<div class="card-body">
				<!--	<div class="alert alert-danger" *ngIf="oui()">
					Mot de passe ou nom d'utilisateur incorrect !!!!
				</div>-->
				<!--<button (click)="testwindow()" type="button" name="button">TestWindow</button>-->
				<form [formGroup]="addForm" novalidate class="form">
					<div  id="x" class="alert alert-danger text-center" *ngIf="errorM">
						{{errorM}}
					</div>
					<div class="input-group form-group">
						<div class="input-group-prepend">
							<span class="input-group-text"><i class="fas fa-user"></i></span>
						</div>
						<input type="text" name="username" formControlName="username" class="form-control" placeholder="email">

					</div>
					<div class="input-group form-group">
						<div class="input-group-prepend">
							<span class="input-group-text"><i class="fas fa-key"></i></span>
						</div>
						<input [type]="show ? 'text' : 'password'" name="mdp" class="form-control" formControlName="mdp"  placeholder="password"/>
						<button (click)="password()"  class="fa fa-eye-slash" ></button>

					</div>

					<div class="form-group">
						<button type="button" name="button" (click)="conect()"  class="btn float-right login_btn" [disabled]="addForm.invalid">Login</button>
					</div>
				</form>
			</div>

		</div>
	</div>
	</div>
