
<div class="row" *ngIf="!card">
        <div class="col-md-4"></div>
        <div class="col-md-4">
            <form  ngNoForm action="{{acsUrl}}" method="POST"  target=_blank enctype="application/x-www-form-urlencoded">
                <div class="form-group">
                    <label for=""> acspareq</label>
                    <input type="text" name="PaReq" id="paReq" value="{{acsPaReq}}"  class="form-control"
                        placeholder="" disabled>
                </div>

                <div class="form-group">
                    <label for=""> acsmd</label>
                    <input type="text" name="MD" id="MD" value="{{acsMd}}" class="form-control" placeholder="" disabled>
                </div>

                <div class="form-group">
                    <label for=""> TermUrl</label>
                    <input type="text" name="TermUrl" id="TermUrl" value="{{termUrl}}" class="form-control"
                        placeholder="" disabled>
                </div>
                <br> <br>
                <div class="form-group">
                    <button  type="submit" class="btn btn-primary"> Valider</button>
                </div>
            </form>
        </div>

        <div class="col-md-4"></div>

    </div>



<div class="container" *ngIf="card">


    <div class="row">
        <!--<div class="col-lg-4 mb-lg-0 mb-3">
            <div class="card p-3">
                <div class="img-box"> <img src="https://www.freepnglogos.com/uploads/visa-logo-download-png-21.png" alt=""> </div>
                <div class="number"> <label class="fw-bold" for="">**** **** **** 1060</label> </div>
                <div class="d-flex align-items-center justify-content-between"> <small><span class="fw-bold">Expiry date:</span><span>10/16</span></small> <small><span class="fw-bold">Name:</span><span>Kumar</span></small> </div>
            </div>
        </div>
        <div class="col-lg-4 mb-lg-0 mb-3">
            <div class="card p-3">
                <div class="img-box"> <img src="https://www.freepnglogos.com/uploads/mastercard-png/file-mastercard-logo-svg-wikimedia-commons-4.png" alt=""> </div>
                <div class="number"> <label class="fw-bold">**** **** **** 1060</label> </div>
                <div class="d-flex align-items-center justify-content-between"> <small><span class="fw-bold">Expiry date:</span><span>10/16</span></small> <small><span class="fw-bold">Name:</span><span>Kumar</span></small> </div>
            </div>
        </div>
        <div class="col-lg-4 mb-lg-0 mb-3">
            <div class="card p-3">
                <div class="img-box"> <img src="https://www.freepnglogos.com/uploads/discover-png-logo/credit-cards-discover-png-logo-4.png" alt=""> </div>
                <div class="number"> <label class="fw-bold">**** **** **** 1060</label> </div>
                <div class="d-flex align-items-center justify-content-between"> <small><span class="fw-bold">Expiry date:</span><span>10/16</span></small> <small><span class="fw-bold">Name:</span><span>Kumar</span></small> </div>
            </div>
        </div>-->
        <div class="col-12 mt-4">
            <div class="card p-3">
                <p class="mb-0 fw-bold h4">Payment par carte de crédit </p>
                <br><br>



                  <circle-progress *ngIf="loader"
                  [percent]="300"
                  [radius]="100"
                  [outerStrokeWidth]="16"
                  [innerStrokeWidth]="4"
                  ></circle-progress>
            </div>
        </div>
        <br><br>

        <div class="col-12" >

            <div class="card p-3">
                <div class="card-body border p-0">

                    <div class="collapse p-3 pt-0" id="collapseExample">

                        <div class="row">

                            <div class="col-8">

                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body border p-0">

                    <p> <a style="margin-left:1px;" class="btn btn-primary p-2 w-100 h-100 d-flex align-items-center justify-content-between" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="true" aria-controls="collapseExample"> <span class="fw-bold " style="margin-left: 25px;"><img class="cardlogo" src="assets/images/logovisa.png" alt="">
</span>  </a>
</p>
                    <div class="collapse show p-3 pt-0" id="collapseExample">
                        <div class="row">
                            <div class="col-lg-5 mb-lg-0 mb-3">
                              <p class="h4 mb-0">Resumé</p>
                              <p class="mb-0"><span class="fw-bold">Total à payer </span><span class="c-green">:{{montant}} FCFA </span></p>
                                <p class="mb-0">Payement securisé et fiable.Vos informations sont chiffrées et protegées</p>
                            </div>

                            <div class="col-lg-7">

                                <form action="" class="form"  [formGroup]="addForm">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form__div"> <input type="text" class="form-control" placeholder=" " name="numero" formControlName="numero"> <label for="numero"  class="form__label">Numero De la Carte</label> </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form__div"> <input type="text" class="form-control" placeholder=" " formControlName="dateexpire" name="dateexpire"> <label for="dateexpire" class="form__label">YYYY - MM</label> </div>
                                        </div>
                                        <div class="col-6">
                                            <!--<p id="cvv">Le numero à trois chiffres derrière la carte</p>-->
                                            <div class="form__div"> <input type="password" class="form-control" placeholder="le code à 3 chiffres à l'arrière de la carte "  formControlName="cvv" name="cvv"> <label for="cvv" class="form__label">Code CVV</label> </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form__div"> <input type="text" class="form-control" placeholder=" " name="nom" formControlName="nom"> <label for="nom" class="form__label">Nom complet sur la carte</label> </div>
                                        </div>
                                        <div class="form-group">
                              						<button type="button" name="button" (click)="payer()"  class="btn float-right login_btn" [disabled]="addForm.invalid">PAYER</button>
                              					</div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
