import { Component, OnInit,ViewChildren,ViewChild } from '@angular/core';
import{FormBuilder,FormGroup,Validators} from '@angular/forms'
import { Router } from "@angular/router";
import{ApiService} from '../api.service';
import{Trace} from '../trace';
import Swall from "sweetalert2"
import {
    EditSettingsModel,
    CommandModel,
    GridComponent
} from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-histoclient',
  templateUrl: './histoclient.component.html',
  styleUrls: ['./histoclient.component.css']
})
export class HistoclientComponent implements OnInit {

  @ViewChild('grid', {static: false})
      public grid: GridComponent;

public formatoptions =  {type:'date', format:'dd/MM/yyyy'}

      readonly editSetting: EditSettingsModel = {
            allowAdding: false,
            allowDeleting: true,
            allowEditing: true,
            allowEditOnDblClick: false,
            showConfirmDialog: true,
            showDeleteConfirmDialog: true
        };

public filterSettings: any;

profil=false
addForm:FormGroup;
addForm1:FormGroup;
addForm2:FormGroup;
traces:Trace[]
traces1:Trace[]

total:any=0
ttc:any=0
errorM=""
errorM1=""
errorM2=""
traces2:Trace[]
affiche:number;
affich:number=0;
affich1:number=0;
loader:boolean
loader2:boolean
aff:number;
taux:number;
jofe:any="../assets/images/jof.JPG"
affich2:number=0;
selectedOption: string;
options = [
    { name: "Id_commande", value: "Id_commande" },
    { name: "Numero de téléphone", value: "Numero de téléphone" }
  ]
  constructor(private formBuilder:FormBuilder,
              private _apiService:ApiService,
              private router:Router) { }

  ngOnInit(): void {
  this.filterSettings = {type: 'Menu'};
      this.initFormOM()
        this.initFormOM1()
          this.initFormOM2()
  }

  initFormOM(){


    //console.log(this.Message_type)
    this.addForm=this.formBuilder.group({
    mail:['',[Validators.required,Validators.email]],
    password:['',[Validators.required]],
    site:['',[Validators.required]],

    });


  }

  initFormOM1(){


    //console.log(this.Message_type)
    this.addForm1=this.formBuilder.group({
    idcommande:['',[Validators.required]],

    });


  }

  initFormOM2(){


    //console.log(this.Message_type)
    this.addForm2=this.formBuilder.group({
    numero:['',[Validators.required]],

    });


  }
  findByINumero(){
    this.loader=true
    this._apiService.getClientTraceByNumero(this.addForm2.value.numero,this.traces[0].id_site).subscribe((data:Trace[])=>
    {
      this.traces2=data
      this.loader=false
     this.affiche=1
     this.affich=1
     this.affich1=0
      this.affich2=1
        this.aff=0
    },
    error => {this.errorM1="Aucune historique pour cette commande"
    setTimeout(()=>{
    this.errorM1=null;
  },5000)},
    () => {console.log('traces chargés avec succès')}

    );
  }
  findByIdcommande(){
    this.loader2=true
    this._apiService.getClientTraceByIdcommande(this.addForm1.value.idcommande,this.traces[0].id_site).subscribe((data:Trace[])=>
    {
      this.traces1=data
      this.loader2=false
      //console.log(this.traces1)
     this.affiche=1
     this.affich=1
     this.affich1=1
      this.affich2=0
        this.aff=0
    },
    error => {this.errorM2="Aucune historique pour cette commande"
    setTimeout(()=>{
    this.errorM2=null;
  },5000)
      console.log('erreur')},
    () => {console.log('traces chargés avec succès')}

    );
  }

  quit(){
    this.affiche=1
    this.affich=1
    this.affich1=0
    this.addForm1.reset()
    this.addForm2.reset()
     this.affich2=0
    this.aff=1
  }
  onSubmit(){
  this.loader2=true
    this._apiService.getClientTrace(this.addForm.value.mail,this.addForm.value.password,this.addForm.value.site).subscribe((data:Trace[])=>
    {
      if (data[0]!=null) {

      this.traces=data
      console.log("les traces ",this.traces)


      this.profil=true
      localStorage.setItem('site',this.addForm.value.site)
      for (let index = 0; index < this.traces.length; index++) {
        this.traces[index]['montant2']=+this.traces[index]['montant']
        if (this.traces[index].api.toString()=="Orange Money") {
          this.traces[index]['tauxOperateur']=this.traces[index].tauxOrange
          this.traces[index]['CommissionOperateur']=this.traces[index].tauxOrange * this.traces[index]['montant2']/100
          this.traces[index]["CommissionJofe"]=this.traces[index].tauxJofe * this.traces[index]['montant2']/100
          this.traces[index]["TTC"]=this.traces[index]['montant2']-(this.traces[index]['CommissionOperateur']+this.traces[index]['CommissionJofe'])
        }
        else if (this.traces[index].api.toString()=="MoovMoney") {
            this.traces[index]['tauxOperateur']=this.traces[index].tauxMoov
            this.traces[index]['CommissionOperateur']=this.traces[index].tauxMoov * this.traces[index]['montant2']/100
            this.traces[index]["CommissionJofe"]=this.traces[index].tauxJofe * this.traces[index]['montant2']/100
            this.traces[index]["TTC"]=this.traces[index]['montant2']-(this.traces[index]['CommissionOperateur']+this.traces[index]['CommissionJofe'])


          }
        else if (this.traces[index].api.toString()=="Carte de crédit") {
              this.traces[index]['tauxOperateur']=this.traces[index].tauxVisa
              this.traces[index]['CommissionOperateur']=this.traces[index].tauxVisa * this.traces[index]['montant2']/100
              this.traces[index]["CommissionJofe"]=this.traces[index].tauxJofe * this.traces[index]['montant2']/100
              this.traces[index]["TTC"]=this.traces[index]['montant2']-(this.traces[index]['CommissionOperateur']+this.traces[index]['CommissionJofe'])

          }
          var date=new Date(this.traces[index]["created_at"])
          var x=date.toUTCString()
          let array=x.split(" ")
          var date1=array[1]+"/"+array[2]+"/"+array[3]
          var h=array[4]
          this.traces[index]["date"]=date1
          this.traces[index]["heure"]=h
        //  var date = new Date(1546108200 * 1000);
          //var date=new Date(this.traces[index]["created_at"]*1000)
          //this.traces[index]["created_at"]=date.toUTCString()

      }
      for (let index = 0; index < this.traces.length; index++) {
        this.total=this.total+this.traces[index]['montant2']
        this.ttc=this.ttc+this.traces[index]['TTC']
        
      }
console.log("total :"+this.total, "ttc :"+this.ttc)
      this.aff=1
         this.affiche=1
      this.loader2=false

     this.affich=1

    this.loader=false
    }else{
      this.loader2=false
        this.loader=false
        Swall.fire("Echec",'identifiants incorrects','error')


    }

    },
    error => { this.loader2=false
         this.errorM="Aucune historique pour ce site ou informations incorrectes"
           setTimeout(()=>{
           this.errorM=null;
         },5000)
            this.router.navigate(['/clientTrace'])
     //console.log('erreur')
   },
    () => {
      console.log('erreur')}

    );
    //console.log(this.traces)

  }

}
