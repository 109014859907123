import { Component, OnInit } from '@angular/core';
import {Client} from "../client";
import{FormBuilder,FormGroup,Validators} from '@angular/forms';
import{ApiService} from '../api.service';
import { Router ,Params,ActivatedRoute} from "@angular/router";
@Component({
  selector: 'app-editclientpass',
  templateUrl: './editclientpass.component.html',
  styleUrls: ['./editclientpass.component.css']
})
export class EditclientpassComponent implements OnInit {
  addForm:FormGroup;
  client:Client
  show:boolean
  constructor(private formBuilder:FormBuilder,
              private _apiService:ApiService,
              private router:Router,
              private routes:ActivatedRoute) { }
  ngOnInit(): void {
    this.addForm=this.formBuilder.group({

 password:['',[Validators.required]],
 password2:['',[Validators.required]],
  });
  }

  password() {
      this.show = !this.show;
  }

  onSubmit(){
    if (this.addForm.value.password.toString()!=this.addForm.value.password2.toString()) {
      alert("Vos mots de passe sont différents")
    }else{
      const routeParams=this.routes.snapshot.params;
  this._apiService.updateClientPassword(this.addForm.value.password, routeParams.id).subscribe(()=>{
  this.router.navigate(['/viewclient']);
  },
  error => {
    alert(error);
  });
    }
  }

}
