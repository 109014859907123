import { Component, OnInit,Pipe, PipeTransform  } from '@angular/core';
import{FormBuilder,FormGroup,Validators} from '@angular/forms';
import { Router,Params,ActivatedRoute } from "@angular/router";
import {ApiService  } from "../api.service";
import Swall from "sweetalert2"
import { DomSanitizer } from '@angular/platform-browser'


@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
amount="10"
body:any
response:any
site:any
acsUrl:any
acsUrl2:any
acsPaReq:any
termUrl="http://visa.jofedigital.com/getresponse"
acsMd:any
card=true
loader:boolean
id_commande:any
montant:any
paymentToken:any
addForm:FormGroup;
addForm2:FormGroup;
lien:any
  constructor(private formBuilder:FormBuilder,
    private _apiService:ApiService,
    private router:Router,
    private routes:ActivatedRoute,private sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    const routeParams=this.routes.snapshot.params
    this.site =routeParams.site
    this.lien =routeParams.lien
    this.montant =routeParams.montant
    this.id_commande =routeParams.id_commande

    this.addForm=this.formBuilder.group({

    numero:['',[Validators.required]],
    dateexpire:['',[Validators.required]],
    cvv:['',[Validators.required]],
    nom:['',[Validators.required]],
    });
  }

/*test(){
  document.getElementById("addForm2").onsubmit(this)
}*/
/*valider(){
  this._apiService.validCardPayement(this.acsMd,this.acsPaReq,this.acsUrl).subscribe((data:any)=>{
    console.log(data)
    this._apiService.cardTrace(cardTrace).subscribe((data:any)=>{
      if(data){
        console.log(data)
        window.location.href=this.lien



      }
    })
  })
}*/

payer(){
    this.loader=true
   this.body={
	order :{
		action:"PURCHASE",
		amount:{
			currencyCode:"XOF",
			value:this.montant
		}
	},
	payment: {
		pan:this.addForm.value.numero,
		expiry:this.addForm.value.dateexpire,
		cvv:this.addForm.value.cvv,
		cardholderName:this.addForm.value.nom


	}
}

/*this._apiService.cardPayement(this.body).subscribe((data:any)=>{
  if(data.outletId){
    console.log(data)
    alert("Payement effectué avec succès")

  }
})*/

const cardTrace={
  site:this.site,
  id_commande:this.id_commande,
  montant:this.montant,
  cartNumber:this.addForm.value.numero
}
this._apiService.access_token().subscribe(
   (resp:any) => {
       this.paymentToken=resp.access_token
       this._apiService.cardPayement(this.body,this.paymentToken).subscribe((data:any)=>{
         if(data.state=="AWAIT_3DS"){
             this.loader=false
             this.card=false
           //console.log(data)
           this.acsMd=data['3ds'].acsMd
           this.acsUrl=data['3ds'].acsUrl

           this.acsPaReq=data['3ds'].acsPaReq
           console.log(this.acsMd)
           console.log(this.acsUrl)
          console.log(this.acsPaReq)
          const body = JSON.stringify(
            { PaReq:this.acsPaReq,
              MD:this.acsMd,
              TermUrl:'http://visa.jofedigital.com/getresponse'
           });
           //console.log(body)
           /*this._apiService.validCardPayement(this.acsMd,this.acsPaReq,this.acsUrl).then((data:any)=>{


             this.response=data.toString()
             let s = document.getElementById('fred');
              s.ownerDocument.write(this.response);

             console.log(data)



           }).catch((error)=>{
             console.log(error)
           })*/
           //
           Swall.fire("Paiement initié avec succès","Merci de confirmer",'success')


           /*this._apiService.cardTrace(cardTrace).subscribe((data:any)=>{
             if(data){
               console.log(data)
               window.location.href=this.lien



             }
           })*/



         }
       })
       //console.log(resp);
   },
   (resp) => {
       console.log("resp-error");
       console.log(resp);
   }
 );





}
}
