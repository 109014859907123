<!--<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
    <div class="row grid-view theme1">
      <div class="col-sm-12 col-md-12 mb-30" >


                <div class="ligneh">
                  j
                </div>
              <div class="entete">

                  <div class="jo1">
                    <img [src]="jofe" style="width:100px;height:90px;"/>
                  </div>
                  <div class="cont">
                    <ul role="tablist"
                        class="nav bg-light nav-pills rounded nav-fill mb-3">
                        <li class="nav-item">
                          <a class="btn btn-primary"
                            onclick="window.open('/apimenu/pzz/joffres/xy', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');">
                          API

                          </a>
                        </li>
                        <li class="nav-item">
                          <a routerLink="/paiement" class="btn btn-primary">Environnement de paiement</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/payementSortant" class="btn btn-primary">Paiements effectués</a>
                        </li>
                        <li class="nav-item">
                           <a routerLink="/clients" class="btn btn-primary"> Nouveau client</a>
                        </li>
                        <li class="nav-item">
                           <a routerLink="/viewclient" class="btn btn-primary"> Liste des clients</a>
                        </li>
                        </ul>

                  </div>



              </div>


                <div class="ligneh">
                  j
                </div>

              <br>
                <div class="historique">

                    <h4>Historique des paiements</h4><br>

                    <form>
                      <div class="form-group form-inline">
                        Recherche: <input class="form-control ml-2" type="text" name="searchTerm" [(ngModel)]="_traceService.searchTerm"/>
                          <span class="ml-3" *ngIf="_traceService.loading$ | async">Chargement...</span>
                      </div>
                    <table class="table table-striped table-bordered table-sm" cellspacing="0" width="100%">
                    <thead>
                    <tr>
                      <th class="th-sm" scope="col">#</th>


                      <th class="th-sm" scope="col" sortable="numero" (sort)="onSort($event)">Numero</th>
                        <th class="th-sm" scope="col" sortable="id_commande" (sort)="onSort($event)">Id_command</th>

                      <th class="th-sm" scope="col" sortable="site" (sort)="onSort($event)">Site</th>
                      <th class="th-sm" scope="col" sortable="montant" (sort)="onSort($event)">Montant</th>
                        <th class="th-sm" scope="col" sortable="api" (sort)="onSort($event)">Api</th>
                      <th class="th-sm" scope="col" sortable="date" (sort)="onSort($event)">Date</th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr *ngFor="let trace of traces$ | async">
                      <th scope="row">{{ trace.id }}</th>

                      <td><ngb-highlight [result]="trace.numero" [term]="_traceService.searchTerm"></ngb-highlight></td>
                        <td><ngb-highlight [result]="trace.id_commande" [term]="_traceService.searchTerm"></ngb-highlight></td>

                      <td><ngb-highlight [result]="trace.id_site" [term]="_traceService.searchTerm"></ngb-highlight></td>
                      <td><ngb-highlight [result]="trace.montant" [term]="_traceService.searchTerm"></ngb-highlight></td>
                      <td><ngb-highlight [result]="trace.api" [term]="_traceService.searchTerm"></ngb-highlight></td>
                  <td>{{trace.created_at | date:'dd/MM/yyyy à H:mm'}}</td>
                      <!-- <td><ngb-highlight [result]="trace.created_at "  [term]="_traceService.searchTerm"></ngb-highlight></td>
                    --><!--</tr>
                    </tbody>
                  </table>
                  <div class="d-flex justify-content-between p-2">
                    <ngb-pagination
                      [collectionSize]="(total$ | async)!" [(page)]="_traceService.page" [pageSize]="_traceService.pageSize">
                    </ngb-pagination>

                    <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="_traceService.pageSize">
                      <option [ngValue]="2">2 enregistrements par page</option>
                      <option [ngValue]="4">4 enregistrements par page</option>
                      <option [ngValue]="6">6 enregistrements parpage</option>
                    </select>
                  </div>
                  </form>
                  <br>
                </div>
                <div class="corps">
                  <h4>Moyens de paiement disponibles </h4><br>
                  <table id="dtBasicExample2" class="table table-striped table-bordered table-sm" cellspacing="0" width="100%">
                    <thead>
                      <tr class="rt">
                        <th class="th-sm">Designation

                        </th>
                        <th class="th-sm">Logo

                        </th>

                      </tr>
                    </thead>

                  <tbody>
                    <tr class="ligne" *ngFor="let ap of api">
                      <td class=" text-center">{{ap.designation}}</td>
                  <td class=" text-center"><img [src]="ap.logo" style="width:70px;height:50px;"  /></td>
                  </tr>
                  </tbody>
                </table>
                <br>

                </div>
      </div>
    </div>
</div>-->


<clr-main-container>
  <clr-header>

    <div class="header-nav" [clr-nav-level]="1">
      <div class="branding text-center">JPAY Administration</div>
    </div>

  </clr-header>

  <div class="content-container">










    <main class="content-area" *ngIf="!affichage">
        <h1>Historiques des paiements</h1>
      <div class="content-container">

        <div style="height: calc(80% - 86px)"><br><br>
                        <ejs-grid [dataSource]='traces' #grid [allowPaging]='true' [allowSorting]='true' [editSettings]="editSetting"
                                  [filterSettings]='filterSettings' [allowFiltering]='true' [allowGrouping]="false"

                                  height="100%">

                            <e-columns>

                            <e-column field='id' headerText='#' textAlign='left' width='150'></e-column>


                                <e-column field='numero' headerText='Numero' textAlign='left' width='150'></e-column>

                                <e-column field='id_commande' headerText='Id_command' textAlign='left' width='150'></e-column>

                                <e-column field='id_site' headerText='Site' textAlign='left' width='150'></e-column>

                                <e-column field='montant' headerText='Montant' textAlign='left' width='150'></e-column>


                                <e-column field='CommissionOperateur' headerText='CommissionOperateur' textAlign='left' width='210'></e-column>
                                <e-column field='CommissionJofe' headerText='CommissionJofe' textAlign='left' width='210'></e-column>
                                <e-column field='TTC' headerText='TTC' textAlign='left' width='150'></e-column>
                                <e-column field='api' headerText='Api' textAlign='left' width='150'></e-column>
                                <e-column field="date"   headerText='Date' textAlign='left' width='150'></e-column>
                                <e-column field="heure"  headerText='Heure' textAlign='left' width='150'></e-column>






                            </e-columns>

                        </ejs-grid>
                    </div>
                        </div>
        <!--<main class="content-area">
          <h1>Historiques des paiements</h1><br>
          <clr-datagrid>
            <clr-dg-column> ID</clr-dg-column>
            <clr-dg-column>Numero</clr-dg-column>
            <clr-dg-column> id_commande</clr-dg-column>
            <clr-dg-column> Site</clr-dg-column>
              <clr-dg-column>Montant</clr-dg-column>
              <clr-dg-column>CommissionOperateur</clr-dg-column>
              <clr-dg-column>CommissionJofe</clr-dg-column>
              <clr-dg-column>TTC</clr-dg-column>
              <clr-dg-column>Api</clr-dg-column>
              <clr-dg-column>Date</clr-dg-column>
                <clr-dg-column>Heure</clr-dg-column>

            <clr-dg-row *clrDgItems="let trace of traces">

              <clr-dg-cell>{{trace.id}}</clr-dg-cell>
              <clr-dg-cell>{{trace.numero}}</clr-dg-cell>
              <clr-dg-cell>{{trace.id_commande}}</clr-dg-cell>
              <clr-dg-cell>{{trace.id_site}}</clr-dg-cell>
              <clr-dg-cell>{{trace.montant}}</clr-dg-cell>
              <clr-dg-cell >{{(trace.montant*(trace.tauxOperateur/100)).toFixed(2)}}</clr-dg-cell>
              <clr-dg-cell >{{(trace.montant*(trace.tauxJofe/100)).toFixed(2)}}</clr-dg-cell>
              <clr-dg-cell>{{(trace.montant-(trace.montant*(trace.tauxOperateur/100)+trace.montant*(trace.tauxJofe/100))).toFixed(2)}}</clr-dg-cell>
              <clr-dg-cell>{{trace.api}}</clr-dg-cell>
              <clr-dg-cell>{{trace.created_at | date:'dd/MM/yyyy'}} </clr-dg-cell>
              <clr-dg-cell>{{trace.created_at | date:'H:mm'}} </clr-dg-cell>

            </clr-dg-row>

            <clr-dg-footer>
              <clr-dg-pagination #pagination [clrDgPageSize]="10">
                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Historiques par page</clr-dg-page-size>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} des {{pagination.totalItems}} historiques
              </clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </main>-->

      <br>
      <h1>Moyens de paiements disponibles</h1><br>
      <clr-datagrid>
        <clr-dg-column> Designation</clr-dg-column>
        <clr-dg-column>Logo</clr-dg-column>


        <clr-dg-row *clrDgItems="let ap of api">

          <clr-dg-cell>{{ap.designation}}</clr-dg-cell>
          <clr-dg-cell><img class="text-center" [src]="ap.logo" style="width:70px;height:50px;"  /></clr-dg-cell>

        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-pagination #pagination [clrDgPageSize]="10">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Moyens de paiement par page</clr-dg-page-size>
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} des {{pagination.totalItems}} moyens de paiement
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>

    </main>
    <nav class="sidenav" [clr-nav-level]="2">
     <section class="sidenav-content">
       <a routerLink="/viewapi" class="nav-link active">Historique</a><br><br>
       <a class="nav-link"
         (click)="testwindow()">
       API

       </a><br><br>

    
         <a routerLink="/paiement" class="nav-link">Environnement de paiement</a><br><br>
         <a routerLink="/payementSortant" class="nav-link">Paiements effectués</a><br><br>

         <a routerLink="/viewclient" class="nav-link"> Liste des clients</a>
          <a routerLink="/token" class="nav-link"> Génération de token</a>
     </section>
    </nav>
  </div>
</clr-main-container>
