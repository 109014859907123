
import { Component,ElementRef, ViewChild,OnInit,Input } from '@angular/core';
import { Router,Params,ActivatedRoute } from "@angular/router";
import {IPayPalConfig ,ICreateOrderRequest } from "ngx-paypal";
import {  ApiService} from "../api.service";
import { ApimenuComponent } from '../apimenu/apimenu.component';
declare var paypal;

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.css']
})
export class PaypalComponent implements OnInit {
  idSite:string
  payPalConfig:IPayPalConfig;
  u:string
  t1:string
  mail:string
  lien:string
  id_commande:string
  montant_usd:string
  currency:string='EUR'
  id_client='AYXs177KJxZMRx5ppS0GnkngMDcRiMwj8GZlMcraZRt2HewxoHmY6GSIbfh-DnI9muE7C5KKsoCjMdpc'
@ViewChild('myPaypalButtons', { static: true }) paypalElement: ElementRef;


  constructor(private _apiService:ApiService,  private routes:ActivatedRoute) { }

  ngOnInit(): void {

    const routeParams=this.routes.snapshot.params;
    this.idSite =routeParams.idSite
    this.lien =routeParams.lien
    this.mail=routeParams.mail
    this.t1=routeParams.montant
    this.u= routeParams.id_commande
    var reP = /f/gi;
    var re0 = /z/gi;
    var re1 = /x/gi;
    var re2 = /y/gi;
    var re3 = /w/gi;
    var re4 = /c/gi;
    var re5 = /p/gi;
    var re6 = /m/gi;
    var re7 = /n/gi;
    var re8 = /i/gi;
    var re9 = /k/gi;


  var u0=this.u.replace(re0, "0");
  var u1 = u0.replace(re1, "1");
  var u2 = u1.replace(re2, "2");
  var u3 = u2.replace(re3, "3");
  var u4 = u3.replace(re4, "4");
  var u5 = u4.replace(re5, "5");
  var u6 = u5.replace(re6, "6");
  var u7 = u6.replace(re7, "7");
  var u8 = u7.replace(re8, "8");
  var u9 = u8.replace(re9, "9");
  var u10=u9
  this.id_commande=u10
  //console.log(this.id_commande)
  var ax = this.t1.replace(reP, ".");
  var a0 = ax.replace(re0, "0");

  var a1 = a0.replace(re1, "1");
  var a2 = a1.replace(re2, "2");
  var a3 = a2.replace(re3, "3");
  var a4 = a3.replace(re4, "4");
  var a5 = a4.replace(re5, "5");
  var a6 = a5.replace(re6, "6");
  var a7 = a6.replace(re7, "7");
  var a8 = a7.replace(re8, "8");
  var a9 = a8.replace(re9, "9");
  this.montant_usd=a9
console.log(this.montant_usd)
this.initConfig()
    /*  paypal
        .Buttons({
          createOrder: (data,actions)=>{
            return actions.order.create({
              purchase_units:[
                {
                  description:'payment de la commande '+this.id_commande+' sur le site '+this.idSite+' total '+this.montant_usd+'EUR',
                  amount:{
                    currency_code:'EUR',
                    value:this.montant_usd
                  }
                }
              ]
            });
          },
          onApprove: function(data, actions) {
                   return actions.order.capture().then(function(details) {


                       alert("Paiement effectué par "+ details.payer.name.given_name+ " "+details.payer.name.surname) ;

                        window.location.href=this.lien
                   });

               }
        ,
          onError:err=>{
            console.log(err);
          }
        })
        .render(this.paypalElement.nativeElement);
  }*/
}
  initConfig(): void {

            //const price = this.price;
            const currency = this.currency;
            const clientId = this.id_client;

            this.payPalConfig = {
                currency: currency,
                clientId: this.id_client,
                // tslint:disable-next-line: no-angle-bracket-type-assertion
                createOrderOnClient: (data) => < ICreateOrderRequest > {
                    intent: 'CAPTURE',
                    purchase_units: [{
                        description:'payment de la commande '+this.id_commande+' sur le site '+this.idSite+' total '+this.montant_usd+'EUR',
                        amount: {
                            currency_code: this.currency,
                            value: this.montant_usd,
                            breakdown: {
                                item_total: {
                                    currency_code: this.currency,
                                    value: this.montant_usd
                                },
                            }
                        },
                        items: [{
                            name: 'PAIEMENT JPAY',
                            quantity: '1',
                            category: 'PHYSICAL_GOODS',
                            unit_amount: {
                                currency_code: this.currency,
                                value: this.montant_usd,
                            },
                        }]
                    }]
                },
                advanced: {
                    commit: 'true'
                },
                style: {
                    label: 'paypal',
                    layout: 'vertical'
                },
                onApprove: (data, actions) => {
                    console.log('onApprove - transaction was approved, but not authorized', data, actions);
                    this._apiService.paypalTrace(this.idSite,this.mail,+this.montant_usd,this.id_commande).subscribe((res:Response)=>{
                    console.log(res)
                    })
                    //const user = this.userService.user;
                    //const cart= this.cartService.cart;
                    /*this.orderService.createOrders(user,cart).then(()=>{
                      console.log("Commande creee avec succes")
                    }).catch((error)=>{
                      console.log(error)
                    })*/
                    actions.order.get().then(details => {
                        alert("Paiement effectué par "+ details.payer.name.given_name+ " "+details.payer.name.surname) ;
                        window.location.href=this.lien
                        console.log('onApprove - you can get full order details inside onApprove: ', details);
                    });
                },
                onClientAuthorization: (data) => {
                    console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);

                },
                onCancel: (data, actions) => {
                    console.log('OnCancel', data, actions);
                },
                onError: err => {
                    console.log('OnError', err);
                },
                onClick: (data, actions) => {
                    console.log('onClick', data, actions);
                },
            };
          }

}
