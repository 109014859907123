import { Component, OnInit } from '@angular/core';
import {Router  } from "@angular/router";
import{ApiService} from '../api.service';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnInit {

  constructor(private _apiService:ApiService,private router:Router) { }

  nom:any
  prenom:any
  telephone:any
  email:any
    ngOnInit(): void {

        this._apiService.getClientBySite(localStorage.getItem('site')).subscribe((data:any)=>{
          console.log(data[0])
          this.nom=data[0].nom
          this.prenom=data[0].prenom
          this.telephone=data[0].telephone
          this.email=data[0].email

          console.log(this.nom,this.prenom)

        })



    }

    update(){
      this.router.navigate(['/resset'])
    }
}
