const BASE='https://www.jpayafrica.com'

const PATH='/jofeapi';
export const API_URLS={
  tokengenerator:BASE+PATH+'/user',
  lien:BASE+PATH,
  Api_URLS:BASE+PATH+'/apis',
  intApi_Url:BASE+PATH+'/initApi',
  Trace_URLS:BASE+PATH+'/traces',
  CopyTrace_URLS:BASE+PATH+'/CopyTrace',
  Onea_URLS:BASE+PATH+'/onea',
  Sonabel_URLS:BASE+PATH+'/sonabel',
  CopyTracePayee:BASE+PATH+'/CopyTracePayee',
  Create_urls:BASE+PATH+'apis',
  GetById_url:BASE+PATH+'/apis/{id}',
  Update_url:BASE+PATH+'apis/{id}',
  Delete_url:BASE+PATH+'apis/{id}'
};


/*const BASE='http://localhost:';
const PORT=8080;
const PATH='/jofeapi';
export const API_URLS={
  tokengenerator:BASE+PORT+PATH+'/user',
  lien:BASE+PORT+PATH,
  Api_URLS:BASE+PORT+PATH+'/apis',
  intApi_Url:BASE+PORT+PATH+'/initApi',
  Trace_URLS:BASE+PORT+PATH+'/traces',
  Onea_URLS:BASE+PORT+PATH+'/onea',
  Sonabel_URLS:BASE+PORT+PATH+'/sonabel',
  CopyTrace_URLS:BASE+PORT+PATH+'/CopyTrace',
  CopyTracePayee:BASE+PORT+PATH+'/CopyTracePayee',
  Create_urls:BASE+PORT+PATH+'apis',
  GetById_url:BASE+PORT+PATH+'/apis/{id}',
  Update_url:BASE+PORT+PATH+'apis/{id}',
  Delete_url:BASE+PORT+PATH+'apis/{id}'
};*/
