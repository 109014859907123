import {Injectable, PipeTransform} from '@angular/core';

import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import{ApiService} from './api.service';
//import {Country} from './country';
//import {COUNTRIES} from './countries';
import {DecimalPipe} from '@angular/common';
import {debounceTime, delay, switchMap, tap} from 'rxjs/operators';
import {SortColumn, SortDirection} from './sortable.directive';



import{Trace} from './traces';

import{HttpClient,HttpHeaders} from '@angular/common/http';
import { API_URLS } from "./config/api.url.config";
import { Component, OnInit } from '@angular/core';

//import { map, filter, switchMap } from 'rxjs/operators';
import { map } from "rxjs/operators";


/*interface SearchResult {
  traces: Trace[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;


*/


@Injectable({
  providedIn: 'root'
})
export class TraceserviceService {
trace_s:Trace[]
  /*private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _traces$ = new BehaviorSubject<Trace[]>([]);
    private _total$ = new BehaviorSubject<number>(0);

    private _state: State = {
      page: 1,
      pageSize: 4,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };*/

    constructor(private pipe: DecimalPipe,private _apiService:ApiService) {
      /*this._search$.pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {
        this._traces$.next(result.traces);
        this._total$.next(result.total);
      });*/

      //this._search$.next();

      this._apiService.getTrace().subscribe((data:Trace[])=>
      {this.trace_s=data
      //console.log(this.trace_s)
    },
      error => {console.log('erreur')},
      () => {console.log('traces chargées avec succès')}

    );

}
}


    /*get traces$() { return this._traces$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._state.page; }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }

    set page(page: number) { this._set({page}); }
    set pageSize(pageSize: number) { this._set({pageSize}); }
    set searchTerm(searchTerm: string) { this._set({searchTerm}); }
    set sortColumn(sortColumn: SortColumn) { this._set({sortColumn}); }
    set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

    private _set(patch: Partial<State>) {
      Object.assign(this._state, patch);
      this._search$.next();
    }

     sort(traces: Trace[], column: SortColumn, direction: string): Trace[] {
      if (direction === '' || column === '') {
        return traces;
      } else {
        return [...traces].sort((a, b) => {
          const res = compare(a[column], b[column]);
          return direction === 'asc' ? res : -res;
        });
      }
    }
     matches(trace: Trace, term: string, pipe: PipeTransform) {

      return trace.id_site.toLowerCase().includes(term.toLowerCase())
        || pipe.transform(trace.id_site).includes(term)
        || pipe.transform(trace.numero).includes(term);
    }
    private _search(): Observable<SearchResult> {
      const {sortColumn, sortDirection, pageSize, page, searchTerm} = this._state;

      // 1. sort
      let traces = this.sort(this.trace_s, sortColumn, sortDirection);

      // 2. filter
      traces = traces.filter(trace => this.matches(trace, searchTerm, this.pipe));
      const total = traces.length;

      // 3. paginate
      traces = traces.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
      return of({traces, total});*/
