import { Component,ElementRef, ViewChild,OnInit } from '@angular/core';
import{ApiService} from '../api.service';

import{Api} from '../api';
import{Client} from '../client';
import { Observable } from 'rxjs';
import { Router,Params,ActivatedRoute } from "@angular/router";
import { NgForm } from '@angular/forms';

import { NgModel } from '@angular/forms';

import{FormBuilder,FormGroup,Validators} from '@angular/forms'
//declare var paypal;
declare var FlutterwaveCheckout;


@Component({
  selector: 'app-apimenu',
  templateUrl: './apimenu.component.html',
  styleUrls: ['./apimenu.component.css']
})
export class ApimenuComponent implements OnInit {
back_image:any="../assets/images/bc.png"
panier_image:any="../assets/images/pan.png"
moov:any="../assets/images/moov.JPG"
paypal_image:any="../assets/images/Paypal.png"
mobil_image:any="../assets/images/orange.JPG"
coris:any="../assets/images/coris.png"
visa_image:any="../assets/images/credit.png"
yup:any="../assets/images/yup.jpg"
m_image:any="../assets/images/moov.png"
jofe:any="../assets/images/jofelogo.jpg"
Message_type:string;
Message_typeMoov:string;
rps:any;
rpsMoov:any;
rpsStatus:any;
notification:boolean=false;
bol:string;
bolMoov:string;
bolCard:string;
bolPaypal:string
apiOrange:string;
apiMoov:string
Message_typeCard:string;
notificationMoov:boolean=false;
total:number;
affichePaypal:number=0;
phone:string;
idSite:string
phoneMoov:string;
montant:number;
montantMoov:number;
otp:string;
id_commande:string;
mailText:string;
api:Api[];
client:Client[]
clients:Client[]
lien:string
responseT:string;
Mobil:number;
Carte:number;
Paypal:number;
Mobi:number;
isClicked:boolean ;
url:string;
urlMoov:string;
xmls:Observable<string>;
result:any;
messagePaypal:string
resultMoov:any;
request_id:string;
message:string;
xc:string
remarks:string;
totaldollar:number
payeeEmail:"ouedraogoaris@gmail.com"
devise:any
montant_usd:string
total_usd:number
t1:string
totEur:number
u:string
tolink:string
loderOrange:boolean
loderMoov:boolean
addForm:FormGroup;
addFormFluter:FormGroup;
addFormMoov:FormGroup;
addForm2:FormGroup;
//@ViewChild('myPaypalButtons', { static: true }) paypalElement: ElementRef;

constructor(private formBuilder:FormBuilder,
            private _apiService:ApiService,
            private router:Router,
            private routes:ActivatedRoute
            ){}

ngOnInit(): void {
const routeParams=this.routes.snapshot.params
this.idSite =routeParams.idSite
this.total=Number(routeParams.montant)
this.id_commande= routeParams.id_commande


/*  var re0 = /z/gi;
  var re1 = /x/gi;
  var re2 = /y/gi;
  var re3 = /w/gi;
  var re4 = /c/gi;
  var re5 = /p/gi;
  var re6 = /m/gi;
  var re7 = /n/gi;
  var re8 = /i/gi;
  var re9 = /k/gi;


var u0=this.u.replace(re0, "0");
var u1 = u0.replace(re1, "1");
var u2 = u1.replace(re2, "2");
var u3 = u2.replace(re3, "3");
var u4 = u3.replace(re4, "4");
var u5 = u4.replace(re5, "5");
var u6 = u5.replace(re6, "6");
var u7 = u6.replace(re7, "7");
var u8 = u7.replace(re8, "8");
var u9 = u8.replace(re9, "9");
var u10=u9
this.id_commande=u10
console.log(this.id_commande)

var a0 = this.t1.replace(re0, "0");
var a1 = a0.replace(re1, "1");
var a2 = a1.replace(re2, "2");
var a3 = a2.replace(re3, "3");
var a4 = a3.replace(re4, "4");
var a5 = a4.replace(re5, "5");
var a6 = a5.replace(re6, "6");
var a7 = a6.replace(re7, "7");
var a8 = a7.replace(re8, "8");
var a9 = a8.replace(re9, "9");
var a10=Number(a9)
console.log(a10)*/




  this.messagePaypal=""

/*  this._apiService.conversion().subscribe((data)=>{
      this.devise=data
      //console.log(this.devise)
      this.totaldollar=this.total/this.devise.quotes.USDXOF
      this.totEur=this.totaldollar/this.devise.quotes.USDEUR


          this.montant_usd=this.totEur.toFixed(2)
          var rePoint = /./gi;
          var rez = /0/gi;
          var reu = /1/gi;
          var red = /2/gi;
          var ret = /3/gi;
          var req = /4/gi;
          var rec = /5/gi;
          var res = /6/gi;
          var resept = /7/gi;
          var rehuit = /8/gi;
          var reneuf = /9/gi;


         var mp=this.montant_usd.replace('.', "f");

          var m0=mp.replace(rez, "z");
          var m1 = m0.replace(reu, "x");
          var m2 = m1.replace(red, "y");
          var m3 = m2.replace(ret, "w");
          var m4 = m3.replace(req, "c");
          var m5 = m4.replace(rec, "p");
          var m6 = m5.replace(res, "m");
          var m7 = m6.replace(resept, "n");
          var m8 = m7.replace(rehuit, "i");
          var m9 = m8.replace(reneuf, "k");
          var m10=m9
          this.tolink=m10
           console.log(this.tolink)
        });*/




this.getClient()
this.getLien()

  this.initFormOM()
this.initFormFluter()

this.addFormMoov=this.formBuilder.group({

numero:['',[Validators.required]]


});

this._apiService.getApi().subscribe((data:Api[])=>{
       this.api=data;

      });
}
cardPayment(){
/*const datas={
  site:this.idSite,
  commendeId:this.id_commande,
  montant:this.total
}
this._apiService.cardExterne(datas).subscribe((data:any)=>{
  console.log(data)
})*/
    var win=window.open('/card/'+this.idSite+'/'+this.total+'/'+this.id_commande+'/'+this.lien,'winname','directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=0,toolbar=no') ;
    win.addEventListener('DOMContentLoaded', function () {

          win.history.replaceState(null, null, 'www.jpay.com');

  })
}

getClient(){
  this._apiService.getClientBySite(this.idSite).subscribe((data:Client[])=>{
         this.client=data;
         this.mailText=this.client[0].email
         console.log(this.mailText)

        });

}



resetFluter(){
  this.addFormFluter.reset()
}

  initFormOM(){

    this.Message_type="";

    this.addForm=this.formBuilder.group({
    numero:['',[Validators.required,Validators.maxLength(8)]],
    otp:['',[Validators.required,Validators.maxLength(6)]],

    });


  }
initFormFluter(){
  this.addFormFluter=this.formBuilder.group({
  numero:['',[Validators.required,Validators.maxLength(8)]],
  mail:['',[Validators.required]],
  nom:['',[Validators.required]],
  prenom:['',[Validators.required]],

  //montant:['',[Validators.required]]
  });
}



openLink(){
  alert("Moyen de paiement bientot disponible !")
  /*this.Mobi=0;
  this.Paypal=0;
  this.Carte=0;
  this.Mobil=0;

  console.log(this.montant_usd)
  var url='paypal/'+this.tolink+'/'+this.idSite+'/'+this.u+'/'+this.lien+'/'+this.mailText
   window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
*/}


Mobils(){
  //alert("Moyen de paiement bientot disponible !")
  this.Mobi=0;
  this.Paypal=0;
  this.Carte=0;
  this.Mobil=0;
  this.Mobil=1;
  this.addFormMoov.reset();
  return this.Mobil;

}
Mobic(){
  //alert("Système de paiement non disponible pour le moment")
  this.Paypal=0;
  this.Carte=0;
  this.Mobil=0;
  this.Mobi=1;
  //this.Mobi=0;
  this.addForm.reset();
  return this.Mobi;
}

getLien(){
  this._apiService.getLientBySite(this.idSite).subscribe((data:Client[])=>{
         this.clients=data;
         this.lien=this.clients[0].lien

        });
}

resetF(){
  this.addForm.reset();


  this.Message_type=" "
  this.notification=false
  this.bol=""
}

resetFMoov(){
    this.addFormMoov.reset();
}

Paypals(){

  this.Mobil=0;
  this.Mobi=0;
  this.Carte=0;
  this.Paypal=1;
  this.affichePaypal=1

  return this.Paypal;

}
Coris(){
  alert("Moyen de paiement non disponible pour le moment")
  this.Mobi=0;
  this.Mobil=0;
  this.Paypal=0;
  this.Carte=0;
  this.addFormMoov.reset()
  this.addForm.reset()
  //return this.Carte;
}

carte(){
//alert("Moyen de paiement non disponible pour le moment")
  this.Mobi=0;
  this.Mobil=0;
  this.Paypal=0;
  this.Carte=1;
  this.addFormMoov.reset()
  this.addForm.reset()
  return this.Carte;
}
onSubmit(){
  this.loderOrange=true
  //https://testom.orange.bf:9008/payment

  this.url= "https://apiom.orange.bf:9007/payment";
this.apiOrange="Orange Money"
  this.phone=this.addForm.value.numero;
  this.otp=this.addForm.value.otp;


  this.result=this._apiService.sendPostRequest(this.mailText,this.id_commande,this.phone,this.otp,this.total,this.url,this.idSite,this.apiOrange).subscribe((res:Response)=>{
    this.rps=res;
    this.Message_type=this.rps.Message
    this.loderOrange=false
    this.notification=true;

    console.log(this.Message_type)
    if(this.Message_type.indexOf("succes")!==-1){
      this.Message_type="Paiement effectué avec succès"
      alert(this.Message_type)
      this.resetF()
      window.location.href=this.lien

    }
    else{
      alert(this.Message_type)
      this.router.navigateByUrl('/apimenu/'+this.t1+'/'+this.idSite+'/'+this.u)

    }

  }
  );

this.bol=""
}
randomNumber() {
  return Math.random() * (100 - 1) + 1;
}
  onSubmitMoov(){
    //console.log(this.addFormMoov.value);
    this.loderOrange=true
    this.apiMoov="Moov Money"
    this.request_id="JPAY-"+this.randomNumber()+"Trans-"+this.addFormMoov.value.numero
    this.message="Payement de "+this.total.toString()+"F à "+ this.idSite+" , veuillez entrez votre code pin pour confirmer"
    this.remarks="TEST"

    this.montantMoov=this.total
    this.phoneMoov=this.addFormMoov.value.numero
    this.resultMoov=this._apiService.sendPostRequestMoov(this.phoneMoov,this.montantMoov,this.message,this.remarks,this.request_id,this.idSite,this.mailText,this.id_commande,this.apiMoov).subscribe((res:Response)=>{
    this.rpsMoov=res
    this.loderOrange=false
    this.notificationMoov=true

    if(this.rpsMoov.message.indexOf("SUCCESS")!==-1){
      this.router.navigateByUrl('/rebour/'+this.request_id+'/'+this.lien)
      //this.Message_type="Paiement effectué avec succès"
      //alert("Transaction intialisée , tapez *555*6*1# sur votre telephone pour finaliser le paiement")
      //window.location.href=this.lien
        this.bolMoov="e"}

      else{
        alert(this.rpsMoov.message)
        this.router.navigateByUrl('/apimenu/'+this.t1+'/'+this.idSite+'/'+this.u)
        }
    },
     (err) => {console.log(err)
       this.loderOrange=false}
    )


    }
checkstatus(){
  this._apiService.Check_transaction_status(this.request_id).subscribe((res:Response)=>{
  this.rpsStatus=res
  //this.loderOrange=false
  //this.notificationMoov=true

  alert(this.rpsStatus.message)
    //this.Message_type="Paiement effectué avec succès"

  })
  console.log(this.request_id)
}

}
