import { Component, OnInit,ViewChildren,ViewChild } from '@angular/core';
import{FormBuilder,FormGroup,Validators} from '@angular/forms'
import { Router } from "@angular/router";
import{ApiService} from '../api.service';
import{Trace} from '../trace';
@Component({
  selector: 'app-resset-pass',
  templateUrl: './resset-pass.component.html',
  styleUrls: ['./resset-pass.component.css']
})
export class RessetPassComponent implements OnInit {
  addForm:FormGroup;
succes:any
error:any
  constructor(private formBuilder:FormBuilder,
              private _apiService:ApiService,
              private router:Router) { }
  ngOnInit(): void {
    this.initFormOM()
  }
  initFormOM(){


    //console.log(this.Message_type)
    this.addForm=this.formBuilder.group({
    mail:['',[Validators.required,Validators.email]],
    password:['',[Validators.required]],
    site:['',[Validators.required]],
    new_password:['',[Validators.required]]

    });


  }
  onSubmit(){
     this._apiService.ressetPassClient(this.addForm.value.mail,this.addForm.value.password,this.addForm.value.new_password,this.addForm.value.site).subscribe((data:any)=>{
       console.log(data)
       if (data.Message=="ok") {
         this.succes="Mot de passe modifié avec succès"
         setTimeout(() => {
           this.succes=null
           this.router.navigate(['profil'])
         }, 3000);

       }
       else if (data.Message=="no"){
         this.error="Echec de modification veuillez reessayer !"
         setTimeout(() => {
           this.error=null

         }, 3000);
       }
     })
  }
}
