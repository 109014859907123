import { Component, OnInit } from '@angular/core';
import {Paiement} from "../paiement";
import {Client} from "../client";
import{FormBuilder,FormGroup,Validators} from '@angular/forms';
import{ApiService} from '../api.service';

import { Router } from "@angular/router";
@Component({
  selector: 'app-paiement',
  templateUrl: './paiement.component.html',
  styleUrls: ['./paiement.component.css']
})
export class PaiementComponent implements OnInit {
affiche :boolean=false
tot :boolean=false
loader:boolean
copyTrace:Paiement[]
copyTraces:Client[]
x:Paiement[]
addForm:FormGroup;
val:number=0
val1:number=0
val2:number
val3:number
  constructor(private router:Router,private _apiService:ApiService,private formBuilder:FormBuilder) { }

  ngOnInit(): void {
    this.loadCopyTrace()
    this.addForm=this.formBuilder.group({

 site:['',[Validators.required]],

  });

  }
Rechercher(){
  this.loader=true
  this._apiService.getCopyBySite(this.addForm.value.site).subscribe((data:Paiement[])=>
  {
    this.copyTrace=data
    console.log(this.copyTrace)
    for (let index = 0; index < this.copyTrace.length; index++) {
      if (this.copyTrace[index].api.toString()=="Orange Money") {
        this.copyTrace[index]['tauxOperateur']=this.copyTrace[index].tauxOrange
      }
      else if (this.copyTrace[index].api.toString()=="MoovMoney") {
          this.copyTrace[index]['tauxOperateur']=this.copyTrace[index].tauxMoov
        }
      else if (this.copyTrace[index].api.toString()=="Carte de crédit") {
            this.copyTrace[index]['tauxOperateur']=this.copyTrace[index].tauxVisa
            console.log("taux Visa",this.copyTrace[index].tauxVisa)
          }

    }
    //console.log(this.copyTrace)
    //console.log(this.copyTrace)
    this.loader=false
    if (this.copyTrace.length===0) {
      this.affiche=true;
      this.tot=true;
      this.val2=0
        this.val3=0
    }
    else{
      for (let index = 0; index < this.copyTrace.length; index++) {
      this.val = this.val+Number(this.copyTrace[index].montant);


      this.val1 = this.val1+(Number(this.copyTrace[index].montant)-(Number(this.copyTrace[index].montant)*0.03));


    }
    this.val2=this.val
      this.val3=this.val1
    //this.val=this.copyTrace[0].total
    this.affiche=true;
    this.tot=true;
    this.val=0}

  },
  error => {console.log('erreur')},
  () => {console.log('traces chargées avec succès')}

  );
}
payer(){
  if (this.val2===0) {
    alert("Pas de nouveau paiement sur "+this.addForm.value.site)
      //this.router.navigateByUrl('/viewapi');

      this.affiche=false
        this.tot=false;
  }
  else {
    this._apiService.deleteBySite(this.addForm.value.site).subscribe((data:Paiement[])=>
    {this.x=data
      //console.log(this.x)
      alert("Paiement à "+this.addForm.value.site+" effectué")
        //this.router.navigateByUrl('/viewapi');
        this.val2=0
        this.affiche=false
          this.tot=false;

    },
    error => {console.log('erreur')},
    () => {console.log('paiement effectué avec succès')}

    );
  }

}

loadCopyTrace(){
this._apiService.getCopytrace().subscribe((data:Client[])=>
{
  this.copyTraces=data
  console.log(this.copyTraces)


},
error => {console.log('erreur')},
() => {console.log('traces chargées avec succès')}

);
}
back(){
  this.router.navigateByUrl('/viewapi')
}

}
