import { Component, OnInit } from '@angular/core';
import{ApiService} from '../api.service';
import { Router } from "@angular/router";
import { Validators,FormGroup,FormBuilder } from "@angular/forms";

@Component({
  selector: 'app-tokengenerator',
  templateUrl: './tokengenerator.component.html',
  styleUrls: ['./tokengenerator.component.css']
})
export class TokengeneratorComponent implements OnInit {
  registerForm:FormGroup
errorMessage;
succesMessage;
token:string
bol:boolean=false
  constructor(private _apiService:ApiService,private fb:FormBuilder
             ) { }

             ngOnInit(): void {
             this.iniRegisterForm()
           }
           iniRegisterForm():void{
             this.registerForm=this.fb.group({

               email:this.fb.control('',[Validators.required]),
               password:this.fb.control('',[Validators.required,Validators.minLength(6)]),

             })
           }

           copyInputMessage(val:string){
             const selBox = document.createElement('textarea');
selBox.style.position = 'fixed';
selBox.style.left = '0';
selBox.style.top = '0';
selBox.style.opacity = '0';
selBox.value = val;
document.body.appendChild(selBox);
selBox.focus();
selBox.select();
document.execCommand('copy');
document.body.removeChild(selBox);

             alert("token copié avec succès")}

onSubmit():void{
 const email=this.registerForm.get('email').value
 const password=this.registerForm.get('password').value
 //console.log(email,password)
 //const newUser:Admin={email:email,password:password};
 this._apiService.getToken(email,password).subscribe((res:any)=>{
  //  this.aut=res.toString();
    if(res.token){
      this.token=res.token
      this.bol=true
      //console.log(res.toString())

    }

    //console.log(res.toString())
  }
);

}

}
