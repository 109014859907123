<body>
<div id="login">
    <div class="container">

        <div id="login-row" class="row justify-content-center align-items-center">
            <div id="login-column" class="col-md-6">
                <div id="login-box" class="col-md-12">
                    <form  [formGroup]="addForm" id="login-form" class="form" >
                        <h3 class="text-center text-info">Connexion</h3>
                        <div class="form-group">
                            <label for="mail" class="text-info">Adresse mail:</label><br>
                            <input type="email" name="mail" id="username" class="form-control" formControlName="mail">
                        </div>
                        <div class="alert alert-succes" *ngIf="succes">
                          {{succes}}
                        </div>
                        <div class="alert alert-danger" *ngIf="error">
                          {{error}}
                        </div>
                        <div class="form-group">
                            <label for="site" class="text-info">Nom du site:</label><br>
                            <input type="text" name="site" id="site" class="form-control" formControlName="site">
                        </div>
                        <div class="form-group">
                            <label for="password" class="text-info">Ancien mot de passe:</label><br>
                            <input type="password" name="password" id="password" class="form-control" formControlName="password">
                        </div>

                        <div class="form-group">
                            <label for="password" class="text-info">Nouveau mot de passe:</label><br>
                            <input type="password" name="new_password" id="new_password" class="form-control" formControlName="new_password">
                        </div>
                        <div class="form-group">
                            <button type="submit"
                                    class="btn btn-primary btn-block"
                                    [disabled]="addForm.invalid"
                                    (click)="onSubmit()"

                            > Soumettre  </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

</body>
