
  <!--<div class="viewc">
    <h4>Liste  des clients  </h4><br>
    <table id="dtBasicExample2" class="table table-striped table-bordered table-sm" cellspacing="0" width="100%">
      <thead>
        <tr class="rt">
          <th class="th-sm">Nom

          </th>
          <th class="th-sm">Prénom

          </th>
          <th class="th-sm">Téléphone

          </th>
          <th class="th-sm">Addresse mail

          </th>
          <th class="th-sm">Site

          </th>
          <th class="th-sm">Domaine du site

          </th>
          <th class="th-sm">Modifier

          </th>
          <th class="th-sm">Supprimer

          </th>


        </tr>
      </thead>

    <tbody>
      <tr class="ligne" *ngFor="let cl of clients">
        <td class=" text-center">{{cl.nom}}</td>
        <td class=" text-center">{{cl.prenom}}</td>
        <td class=" text-center">{{cl.telephone}}</td>
        <td class=" text-center">{{cl.email}}</td>
        <td class=" text-center">{{cl.site}}</td>
        <td class=" text-center">{{cl.lien}}</td>
        <td class=" text-center"><button type="button" name="button"  (click)="edit(cl)" class="btn btn-success">Modifier</button></td>
          <td class=" text-center"><button type="button" name="button"  (click)="delete(cl)" class="btn btn-danger">Supprimer</button></td>





    </tr>
    </tbody>
  </table>
  </div>




<br>-->





<clr-main-container>
  <clr-header>

    <div class="header-nav" [clr-nav-level]="1">
      <div class="branding text-center">JPAY Administration</div>
    </div>

  </clr-header>

  <div class="content-container">
    <main class="content-area" >
       <a routerLink="/clients" class="btn btn-primary"> Ajouter un client</a><br>
      <clr-datagrid>
        <clr-dg-column> Nom</clr-dg-column>
        <clr-dg-column>Prénom</clr-dg-column>
        <clr-dg-column> Téléphone</clr-dg-column>
        <clr-dg-column> Adresse mail</clr-dg-column>
          <clr-dg-column>Site</clr-dg-column>
          <clr-dg-column>Domaine</clr-dg-column>
          <clr-dg-column>Taux</clr-dg-column>
          <clr-dg-column>TauxMoov</clr-dg-column>
          <clr-dg-column>TauxOrange</clr-dg-column>
          <clr-dg-column>TauxJofe</clr-dg-column>
          <clr-dg-column>TauxVisa</clr-dg-column>
          <clr-dg-column>Modifier</clr-dg-column>
          <clr-dg-column>Modifier le mot de passe</clr-dg-column>
            <clr-dg-column>Afficher le token</clr-dg-column>

          <clr-dg-column>Supprimer</clr-dg-column>

        <clr-dg-row *clrDgItems="let cl of clients">
          <!-- debut du code des actions-->

          <!-- fin du code des actions-->
          <clr-dg-cell>{{cl.nom}}</clr-dg-cell>
          <clr-dg-cell>{{cl.prenom}}</clr-dg-cell>
          <clr-dg-cell>{{cl.telephone}}</clr-dg-cell>
          <clr-dg-cell>{{cl.email}}</clr-dg-cell>
          <clr-dg-cell>{{cl.site}}</clr-dg-cell>
          <clr-dg-cell>{{cl.lien}}</clr-dg-cell>
          <clr-dg-cell>{{cl.taux}}</clr-dg-cell>
          <clr-dg-cell>{{cl.tauxMoov}}</clr-dg-cell>
          <clr-dg-cell>{{cl.tauxOrange}}</clr-dg-cell>
          <clr-dg-cell>{{cl.tauxJofe}}</clr-dg-cell>
          <clr-dg-cell>{{cl.tauxVisa}}</clr-dg-cell>
          <clr-dg-cell> <button type="button" name="button"  (click)="edit(cl)" class="btn btn-primary"><i class="fa fa-edit"></i></button></clr-dg-cell>
          <clr-dg-cell> <button type="button" name="button"  (click)="ressetPassword(cl)" class="btn btn-primary"><i class="fa fa-key"></i></button></clr-dg-cell>
          <clr-dg-cell> <button type="button" name="button"  (click)="accessToken(cl)" class="btn btn-primary"><i class="fa fa-lock"></i></button></clr-dg-cell>

          <clr-dg-cell> <button type="button" name="button"  (click)="delete(cl)" class="btn btn-danger"><i class="fa fa-trash"></i></button></clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-pagination #pagination [clrDgPageSize]="10">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Clients par page</clr-dg-page-size>
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} des {{pagination.totalItems}} clients
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </main>
    <nav class="sidenav" [clr-nav-level]="2">
     <section class="sidenav-content">
       <a routerLink="/viewapi" class="nav-link"> Historique</a><br><br>
       <a class="nav-link"
         onclick="window.open('/apimenu/pzz/joffres/xy', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');">
       API

       </a><br><br>
         <a routerLink="/paiement" class="nav-link">Environnement de paiement</a><br><br>
         <a routerLink="/payementSortant" class="nav-link">Paiements effectués</a><br><br>

         <a routerLink="/viewclient" class="nav-link active"> Liste des clients</a>
     </section>
    </nav>
  </div>
</clr-main-container>
